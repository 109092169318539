import React, { useCallback, useEffect, useState } from 'react';
import { Stack } from '@fluentui/react';
import { IStackTokens, IStackItemStyles, mergeStyles } from '@fluentui/react';
import '../../../styling/css/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentCard, DocumentCardTitle } from '@fluentui/react/lib/DocumentCard';
import JSONPretty from 'react-json-pretty';
import Services from '../Services/Services';
import Contacts from '../Contacts/Contacts';
import Microsoft from '../Microsoft/Microsoft';
import {
  ElxScopedContainer,
  IScope,
  useScope,
  uxAddContainerMessageAction,
  uxClearContainerMessageAction,
} from '@elixir/fx';
import { getLogger, ILogger } from '@elixir/telemetry';

import {
  ElxCopy,
  ElxIconButton,
  ElxMasterDetailPanel,
  ElxPanel,
  ElxSecondaryButton,
  ElxTabControlOrientation,
  IElxMasterDetailTabProps,
  IMessage,
  isNullOrWhitespace,
  ElxMessageBarType,
  PanelSize,
} from '@elixir/components';

import { isEmpty, isEqual } from 'lodash';
import { useLocation } from 'react-router';
import { getContractDetails, getContract } from '../../../store/action';
import { IContractColumns } from '../../../store/types';
import { ErrorType, IError } from '../../../../utility/errorHandling'
import { PermissionErrorMessage } from '../../../../utility/PermissionErrorMessage';
interface IPanelExampleState {
  show: boolean;
  size: PanelSize;
  message?: IMessage;
  isLoading: boolean;
  fillBackground: boolean;
}

// Setup styles
const stackItemStyles: IStackItemStyles = {
  root: {
    //background: DefaultPalette.themePrimary,
    //color: DefaultPalette.white,
    padding: 5,
    cursor: 'Pointer',
    flex: 1,
  },
};
const stackItemStyles2: IStackItemStyles = {
  root: {
    //background: DefaultPalette.themePrimary,
    //color: DefaultPalette.white,
    padding: 5,
    cursor: 'Pointer',
    textAlign: 'right',
  },
};
export const cardContentStackStyles = mergeStyles({
  width: 200,
});
export const packageStyles = mergeStyles({
  width: 250,
  marginBottom: 0,
});
export const cardContentStackTokens: IStackTokens = {
  childrenGap: 'l1',
};
export const cardContentStackTokens2: IStackTokens = {
  childrenGap: '1',
};
export const cardPackageTokens: IStackTokens = {
  childrenGap: 'l1',
};
export const cardContentStackStyles2 = mergeStyles({
  width: 220,
});

export const Overview = (props: { scope: IScope }) => {
  const logger = getLogger();
  const scope = useScope();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const Id = queryParams.get('id') ?? '';
  const contractColumns: IContractColumns[] = [];
  const contractColumns2: { [key: string]: Array<any> } = {};

  // Subscribe for data changes
  const error: IError | undefined = useSelector(
    (state: any) => state?.modules?.Contract?.error
  );
  const contract: Array<Object> = useSelector((state: any) => state?.modules?.Contract?.contract);
  const contractAllDetails: Array<Object> = useSelector(
    (state: any) => state?.modules?.Contract?.contractDetails,
  );
  const opsConfig: any = useSelector((state: any) => state?.modules?.Contract?.config);

  // Setup state variables
  const [wizardPanelOpen, setWizardPanelOpen] = useState(false);
  const [packageID, setPackageID] = useState('');
  const [defaultIndex, setDefaultIndex] = useState(0);

  const [copyJSON, setCopyJSON] = React.useState<string>('');
  const closeWizard = useCallback(() => {
    setWizardPanelOpen(false);
    setDefaultIndex(0);
  }, []);

  const [panelState, setPanelState] = React.useState<IPanelExampleState>({
    show: false,
    size: PanelSize.medium,
    isLoading: false,
    fillBackground: false,
  });
  const { show, size, isLoading, fillBackground, message } = panelState;

  // Callback for tab changes
  const tabChange = (tabIndex: number, tab: IElxMasterDetailTabProps) => {
    setDefaultIndex(tabIndex);
  };

  // Callback to open Contract Details panel and request detailed data
  function openPanel(
    sc: IScope,
    id: string,
    size: PanelSize,
    fillBackground: boolean,
    setPanelState: React.Dispatch<React.SetStateAction<IPanelExampleState>>,
  ): void {
    setPanelState(state => ({
      ...state,
      show: true,
      size,
      fillBackground,
    }));
    dispatch(getContractDetails(id, sc));
  }

  // Callback to display More Details 
  function moreDetailsPanel(id: string) {
    setPackageID(id);
    setWizardPanelOpen(true);
  }

  // Function to build the page tabs
  const getTabs = (
    logger: ILogger,
    setDefaultIndex: (defaultIndex: number) => void,
  ): IElxMasterDetailTabProps[] => {
    let isFederal = opsConfig?.isFederal;
    let tabs: IElxMasterDetailTabProps[] = [
      {
        key: '3',
        name: 'MICROSOFT CONTACTS',
        component: (
          <div>
            <ElxScopedContainer scope={scope}>
              <Microsoft packageID={packageID} scope={scope} />
            </ElxScopedContainer>
          </div>
        ),
        primaryButton: {},
        cancelButton: { text: 'Close' },
      },
    ];

    if (!isFederal) {
      let braavosTabs: IElxMasterDetailTabProps[] = [
        {
          key: '1',
          name: 'SERVICES',
          component: (
            <ElxScopedContainer scope={scope}>
              <Services packageID={packageID} scope={scope} />
            </ElxScopedContainer>
          ),
          primaryButton: {},
          cancelButton: { text: 'Close' },
        },
        {
          key: '2',
          name: 'CUSTOMER CONTACTS',
          component: (
            <div>
              <ElxScopedContainer scope={scope}>
                <Contacts packageID={packageID} scope={scope} />
              </ElxScopedContainer>
            </div>
          ),
          primaryButton: {},
          cancelButton: { text: 'Close' },
        },
      ];
      tabs = braavosTabs.concat(tabs);
    }
    return tabs;
  };

  // Initiate search for requested Contract
  useEffect(() => {
    if (!isNullOrWhitespace(Id)) {
      dispatch(getContract(Id, props.scope));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // [] = on initial pass only

  // Do something with any returned data
  
  // Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: (
                <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />
              )
            },
            props.scope)
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.'
            },
            props.scope)
        );
      }
    } else {
      // Clear any existing error message
      dispatch(uxClearContainerMessageAction(props.scope));
    }
  });

  // Check if there is no contract data to display
  if (contract === undefined || isEqual(contract, {}) || isEmpty(contract) || isNullOrWhitespace(contract.toString())) {
    return (
      <div className="default-contract">
        <b style={{ color: '#605E5C', letterSpacing: 2 }}>
          THERE ARE NO RESULTS TO DISPLAY. START SEARCHING !{' '}
        </b>
      </div>
    );
  }

  // Otherwise, display contract data
  else {
    let tabs = getTabs(logger, setDefaultIndex);
    if (!isEmpty(contract)) {
      // eslint-disable-next-line array-callback-return
      contract?.map((x: any) => {
        // var currentDate = new Intl.DateTimeFormat('en-US', {
        //   year: 'numeric',
        //   month: 'short',
        //   day: '2-digit',
        // }).format(new Date())

        var startDate = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        }).format(new Date(x?.startDate));
        var endDate = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        }).format(new Date(x?.endDate));
        var today = new Date();
        var end = new Date(x?.endDate);

        const contractJSON: any = {
          'UNIQUE ID': x?.uniqueId ?? 'N/A',
          ID: x?.id ?? 'N/A',
          TITLE: x.title ?? 'N/A',
          'CONTRACT NUMBER': x.agreementNumber ?? 'N/A',
          'START DATE': startDate ?? 'N/A',
          'END DATE': endDate ?? 'N/A',
          STATUS: today < end ? 'Active Contract' : 'Expired Contract',
        };
        const contractPackages: Array<any> = [];
        if (x?.packages) {
          // eslint-disable-next-line array-callback-return
          x?.packages?.map((pack: any, index: number) => {
            contractPackages.push(pack);
          });
        }
        contractColumns2[x.uniqueId] = contractPackages;
        contractColumns.push(contractJSON);
      });
    }

    var JSONPrettyMon = require('react-json-pretty/dist/monikai');

    return (
      <div style={{ padding: 20 }}>
        <ElxPanel
          headerText="Contracts JSON"
          isOpen={show}
          size={size}
          fillBackground={fillBackground}
          message={message}
          isLoading={isLoading}
          onDismiss={() => setPanelState({ ...panelState, show: false })}>
          <div className="contract-modal">
            <ElxCopy
              textToCopy={JSON.stringify(contract, null, 2)}
              onClick={() => setCopyJSON(copyJSON)}>
              <ElxIconButton text="Copy" iconProps={{ iconName: 'Info' }} />
            </ElxCopy>
            <ElxScopedContainer scope={scope}>
              <JSONPretty
                data={contractAllDetails}
                theme={JSONPrettyMon}
                style={{ padding: 10 }}></JSONPretty>
            </ElxScopedContainer>

            {/* <div>
              <pre>{JSON.stringify(contractAllDetails, null, 2)}</pre>
            </div> */}
          </div>
        </ElxPanel>

        <ElxMasterDetailPanel
          isOpen={wizardPanelOpen}
          wizard={true}
          selectedTabIndex={defaultIndex}
          headerText="MORE DETAILS"
          tabs={tabs}
          onDismiss={closeWizard}
          onTabChange={tabChange}
          tabsOrientation={ElxTabControlOrientation.Horizontal}
        />

        {
          contractColumns.map((column: IContractColumns, index: number) => {
            return (
              <div key={`${column['UNIQUE ID']}`}>
                <DocumentCard className="sub-card-styles-packages">
                  <Stack horizontal>
                    <Stack.Item styles={stackItemStyles}>
                      <DocumentCardTitle
                        title="CONTRACT DETAILS"
                        className="contract-panel-name"
                        shouldTruncate
                      />
                    </Stack.Item>
                    <Stack.Item align="start" styles={stackItemStyles2}>
                      <ElxSecondaryButton
                        text="SHOW CONTRACTS JSON"
                        title="json"
                        className="json-button"
                        onClick={() =>
                          openPanel(scope, column.ID, PanelSize.large, false, setPanelState)
                        }
                      />
                    </Stack.Item>
                  </Stack>
                  <Stack horizontal tokens={cardContentStackTokens} wrap>
                    {Object.entries(column).map(([keys, value], index) => {
                      return (
                        <Stack className={cardContentStackStyles} key={`${value}${index}`}>
                          <Stack.Item key={keys + index}>
                            <b>{keys}</b>: {value}
                          </Stack.Item>
                        </Stack>
                      );
                    })}
                  </Stack>
                  <Stack>
                    <br />
                    <Stack>
                      {contractColumns2[column['UNIQUE ID']].map((value, index) => {
                        const startDate = new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        }).format(new Date(value.startDate));
                        const endDate = new Intl.DateTimeFormat('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        }).format(new Date(value.endDate));
                        return (
                          <DocumentCard
                            className="sub-card-styles-packages"
                            key={`${value.id}${index}`}>
                            <Stack horizontal>
                              <Stack.Item styles={stackItemStyles}>
                                <DocumentCardTitle
                                  className="customer-contract-title"
                                  title="PACKAGE DETAILS"
                                  shouldTruncate
                                />
                              </Stack.Item>
                              <Stack.Item align="start" styles={stackItemStyles2}>
                                <ElxSecondaryButton
                                  text="MORE DETAILS"
                                  title="json"
                                  className="json-button"
                                  onClick={() => moreDetailsPanel(value.id)}
                                />
                              </Stack.Item>
                            </Stack>
                            <Stack horizontal tokens={cardContentStackTokens2} wrap>
                              <Stack.Item className={cardContentStackStyles2}>
                                <b>PACKAGE ID:</b> {value.id}
                              </Stack.Item>

                              <Stack.Item className={cardContentStackStyles2}>
                                <b>START DATE:</b> {startDate}
                              </Stack.Item>
                              <Stack.Item className={cardContentStackStyles2}>
                                <b>END DATE:</b> {endDate}
                              </Stack.Item>
                              <Stack.Item className={cardContentStackStyles2}>
                                <b>PACKAGE NAME:</b> {value.title}
                              </Stack.Item>
                              <Stack.Item className={cardContentStackStyles2}>
                                <b>SERVICE TYPE:</b> {value.packageType}
                              </Stack.Item>
                              <Stack.Item className={cardContentStackStyles2}>
                                <b>CSAM:</b> {value.tam?.alias ?? 'N/A'}
                              </Stack.Item>
                              <Stack.Item className={cardContentStackStyles2}>
                                <b>BACKUP CSAM:</b> {value.bam?.alias ?? 'N/A'}
                              </Stack.Item>
                              <Stack.Item className={cardContentStackStyles2}>
                                <b>OFFERINGS ID:</b> {value.serviceOfferingId}
                              </Stack.Item>
                            </Stack>
                          </DocumentCard>
                        );
                      })}
                    </Stack>
                  </Stack>
                </DocumentCard>
              </div>
            );
          })
        }
      </div>
    );
  }
};

export default Overview;
