import {
  ElxScopedContainer,
  IScope,
  useScope,
  uxAddContainerMessageAction,
  uxClearContainerMessageAction,
} from '@elixir/fx';
import { getLogger, ILogger } from '@elixir/telemetry';
import {
  ElxDateTimePicker,
  IElxDateTimePickerTimeZone,
  IElxColumn,
  ITableAction,
  ElxTableContainer,
  IElxSearchBoxProps,
  IElxContainerProps,
  ElxMasterDetailPanel,
  IElxMasterDetailTabProps,
  ElxTabControlOrientation,
  ElxDateTimePickerDisplayMode,
  PanelSize,
  ElxMessageBarType,
} from '@elixir/components';
import { isEmpty, isEqual } from 'lodash';
import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getScripts, getTraces } from '../store/action';
import { IScriptsColumn, CLEAR_STATE } from '../store/types';
import { ResultStatus, ErrorType, IError } from '../../utility/errorHandling';
import { PermissionErrorMessage } from '../../utility/PermissionErrorMessage';
import '../styling/css/style.css';
import RunScript from './RunScript';
import { SelectionMode, Stack } from '@fluentui/react';

export const Scripts = (props: {
  scope: IScope;
  searchProps?: IElxSearchBoxProps;
  containerProps?: IElxContainerProps;
}) => {
  const logger = getLogger();
  const scope = useScope();
  const dispatch = useDispatch();
  let scriptColumn: IScriptsColumn[] = [];
  const scriptColumn2: IScriptsColumn[] = [];
  const scriptInput: { [key: string]: IScriptsColumn } = {};
  const searchBoxProps = {
    ...props.searchProps,
  };
  var today = new Date();
  today.setHours(2);
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.toDateString();
  const todayDefault = new Date(today);
  const yesterdayDefault = new Date(yesterday);
  const timeZones: IElxDateTimePickerTimeZone[] = [
    { utcOffsetMinutes: 0, symbol: 'UTC' },
    { utcOffsetMinutes: -480, symbol: 'PST' },
    { utcOffsetMinutes: -420, symbol: 'PDT' },
    { utcOffsetMinutes: -300, symbol: 'EST' },
    { utcOffsetMinutes: -240, symbol: 'EDT' },
    { utcOffsetMinutes: 330, symbol: 'IST' },
    { utcOffsetMinutes: 480, symbol: 'CST' },
    { utcOffsetMinutes: 540, symbol: 'JST' },
  ];

  // Subscribe for data changes
  const scriptList: Array<Object> = useSelector(
    (state: any) => state?.modules?.Scripts?.scriptList,
  );
  const error: IError | undefined = useSelector((state: any) => state?.modules?.Scripts?.error);
  const traceLog = useSelector((state: any) => state?.modules?.Scripts?.traces);
  const runScriptStatus: ResultStatus | undefined = useSelector(
    (state: any) => state?.modules?.Scripts?.runScriptStatus,
  );
  const cancelScriptStatus: ResultStatus | undefined = useSelector(
    (state: any) => state?.modules?.Scripts?.cancelScriptStatus,
  );
  const getTracesStatus: ResultStatus | undefined = useSelector(
    (state: any) => state?.modules?.Scripts?.getTracesStatus,
  );

  // Setup state variables
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState({
    disabled: false,
    readOnly: false,
  });
  const [panelName, setPanelName] = useState('');
  const [panelDesc, setPanelDesc] = useState('');
  const [inputScript, setInputScript] = useState('');
  const [scriptData, setScriptData] = useState('');
  const [startDateTime, setStartDateTime] = useState<any>(Date());
  const [endDateTime, setEndDateTime] = useState(Date());
  const [wizardPanelOpen, setWizardPanelOpen] = useState(false);
  const [defaultIndex, setDefaultIndex] = useState(0);

  // Build tabs for Run Script detail panel
  const getTabs = (
    logger: ILogger,
    setDefaultIndex: (defaultIndex: number) => void,
  ): IElxMasterDetailTabProps[] => {
    const tabs: IElxMasterDetailTabProps[] = [
      {
        key: '1',
        name: 'RUN SCRIPT',
        component: (
          <RunScript
            defaultValue={JSON.stringify(inputScript, null, 3)}
            changedValue={scriptData}
            changedScriptValue={changedScriptValue}
            scope={scope}
            panelName={panelName}
          />
        ),
        primaryButton: {
          text: 'Go To Traces',
        },
        cancelButton: {
          text: 'Close',
        },
      },
      {
        key: '2',
        name: 'GET TRACES',
        component: (
          <ElxScopedContainer scope={scope}>
            <br />
            <ElxDateTimePicker
              label="Start Date"
              id={'side-by-side-startDate'}
              timeZones={timeZones}
              defaultValue={`${yesterdayDefault}`}
              displayMode={ElxDateTimePickerDisplayMode.SideBySide}
              onSelectDateTime={(dateTime?: string) =>
                setStartDateTime(dateTime!)
              }></ElxDateTimePicker>
            <ElxDateTimePicker
              label="End Date"
              id={'side-by-side-endDate'}
              timeZones={timeZones}
              defaultValue={`${todayDefault}`}
              displayMode={ElxDateTimePickerDisplayMode.SideBySide}
              onSelectDateTime={(dateTime?: string) => setEndDateTime(dateTime!)}
              className="script-date-picker"></ElxDateTimePicker>
            <br />
            <ElxTableContainer
              containerProps={{
                headerText: 'TRACE LOGS',
                fillBackground: false,
                compact: false,
              }}
              tableProps={{
                compact: false,
                columns: getColumnsTraces(),
                items: scriptColumn2,
                selectionMode: SelectionMode.none,
              }}
              searchBoxProps={searchBoxProps}></ElxTableContainer>
            {/* <ElxTable
								items={scriptColumn2}
								columns={getColumnsTraces()}
								selectionMode={SelectionMode.none}
							/> */}
          </ElxScopedContainer>
        ),
        primaryButton: {
          text: 'Get Traces',
          onClick: () => {
            getTraceLogs(panelName, startDateTime, endDateTime, scope);
          },
        },
        cancelButton: { text: 'Close' },
      },
    ];
    return tabs;
  };

  function closeWizard() {
    dispatch({ type: CLEAR_STATE });
    setWizardPanelOpen(false);
    setDefaultIndex(0);
  }

  const tabs = getTabs(logger, setDefaultIndex);

  function tabChange(tabIndex: number, tab: IElxMasterDetailTabProps) {
    logger.debug('Example: Update coming from the masterdetail panel current Tab', tabIndex, tab);

    dispatch({ type: CLEAR_STATE });
    setDefaultIndex(tabIndex);
    logger.debug(defaultIndex);
  }

  function changedScriptValue(newscript: string) {
    setScriptData(newscript);
  }

  function WorkspaceDataPanel(Name: string, Desc: string, Input: any) {
    setWizardPanelOpen(true);
    setPanelName(Name);
    setPanelDesc(Desc);
    setInputScript(JSON.stringify(Input));
    changedScriptValue(JSON.stringify(Input, null, 2));
  }

  function getTraceLogs(scriptName: string, start: string, end: string, sc: IScope) {
    dispatch(getTraces(scriptName, start, end, sc));
  }

  // Request list of scripts
  useEffect(() => {
    dispatch(getScripts(props.scope));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      var sc =
        runScriptStatus !== ResultStatus.Unknown ||
        cancelScriptStatus !== ResultStatus.Unknown ||
        getTracesStatus !== ResultStatus.Unknown
          ? scope
          : props.scope;
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />,
            },
            sc,
          ),
        );
      } else {
        // Trace logs show up in the panel along with results from Run/CancelScript.
        // In order for trace error messages to show up there, we need to use the
        // scope created in this module.  For errors associated with getting the list
        // of scripts, we need to use the scope passed into this module, i.e.,
        // props.scope.
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.',
            },
            sc,
          ),
        );
      }
    } else {
      // Clear any existing error message
      dispatch(uxClearContainerMessageAction(props.scope));
      dispatch(uxClearContainerMessageAction(scope));
    }
  });

  // Check if there are no scripts
  if (scriptList === undefined || isEqual(scriptList, {}) || isEmpty(scriptList)) {
    return (
      <div className="default-contract">
        <b style={{ color: '#605E5C', letterSpacing: 2 }}>THERE ARE NO RESULTS TO DISPLAY </b>
      </div>
    );
  }

  // Otherwise, show script data
  else {
    if (scriptList?.length > 0) {
      // eslint-disable-next-line array-callback-return
      scriptColumn = scriptList?.map((x: any) => {
        const scriptJSON = {
          Name: x?.scriptName,
          Description: x?.description ?? 'N/A',
          Disabled: x?.disabled.toString() ?? 'N/A',
          Restricted: x?.restricted.toString() ?? 'N/A',
          Input: x?.inputs,
        };
        const inputlist = x?.inputs;
        scriptInput[x.scriptName] = inputlist;
        return scriptJSON;
      });
    }

    if (traceLog?.length > 0) {
      // eslint-disable-next-line array-callback-return
      traceLog?.map((x: any) => {
        const scriptJSON = {
          Timestamp: x?.timestamp,
          Message: x?.message ?? 'N/A',
          RunBy: x?.runBy ?? 'N/A',
          CorrelationID: x?.correlationId ?? 'N/A',
        };
        scriptColumn2.push(scriptJSON);
      });
    }
    return (
      <ElxTableContainer
        containerProps={{
          headerText: 'SCRIPTS',
          fillBackground: false,
          compact: false,
        }}
        tableProps={{
          compact: false,
          columns: getColumns(),
          items: scriptColumn,
          actions: actions(),
          selectionMode: SelectionMode.none,
        }}
        searchBoxProps={searchBoxProps}>
        <ElxMasterDetailPanel
          isOpen={wizardPanelOpen}
          wizard={true}
          selectedTabIndex={defaultIndex}
          headerText={panelName.toUpperCase()}
          onRenderSubHeader={() => <Stack.Item>{panelDesc.toUpperCase()}</Stack.Item>}
          tabs={tabs}
          onDismiss={closeWizard}
          onTabChange={tabChange}
          size={PanelSize.large}
          tabsOrientation={ElxTabControlOrientation.Horizontal}
        />
      </ElxTableContainer>
    );
  }

  function actions(): ITableAction[] {
    return [
      {
        key: 'Run',
        text: 'Run',
        disableBulkAction: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onAction: (item: any) => {
          WorkspaceDataPanel(item['Name'], item['Description'], scriptInput[item['Name']]);
        },
        iconProps: { iconName: 'EditSolid12' },
        trackingContext: {
          label: 'CustomActionLabel',
        },
      },
    ];
  }

  function getColumns(): IElxColumn[] {
    return [
      {
        key: '2',
        name: 'Name',
        fieldName: 'Name',
        minWidth: 100,
        maxWidth: 250,
        isResizable: false,
      },
      {
        key: '3',
        name: 'Description',
        fieldName: 'Description',
        minWidth: 150,
        //maxWidth: 800,
        isResizable: true,
        isMultiline: true,
      },
      {
        key: '4',
        name: 'Disabled',
        fieldName: 'Disabled',
        minWidth: 70,
        //maxWidth: 70,
        isResizable: true,
      },
      {
        key: '5',
        name: 'Restricted',
        fieldName: 'Restricted',
        minWidth: 70,
        //maxWidth: 70,
        isResizable: true,
      },
    ];
  }
  function getColumnsTraces(): IElxColumn[] {
    return [
      {
        key: '2',
        name: 'Timestamp (UTC)',
        fieldName: 'Timestamp',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isMultiline: true,
        onRender: item => {
          return (
            <span>
              {new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZone: 'UTC',
              }).format(new Date(item['Timestamp']))}
            </span>
          );
        },
      },
      {
        key: '3',
        name: 'Message',
        fieldName: 'Message',
        minWidth: 200,
        maxWidth: 500,
        isResizable: true,
        isMultiline: true,
      },
      {
        key: '4',
        name: 'RunBy',
        fieldName: 'RunBy',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isMultiline: true,
      },
      {
        key: '5',
        name: 'CorrelationID',
        fieldName: 'CorrelationID',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        isMultiline: true,
      },
    ];
  }
};

export default Scripts;
