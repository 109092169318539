import { Http, containerManagedAction, IScopedPayload } from '@elixir/fx';
import { Logger } from '@elixir/telemetry';
import {
  GET_ANNOUNCEMENTS_REQUESTED,
  SAVE_ANNOUNCEMENT_REQUESTED,
  ANNOUNCEMENT_IMAGES_REQUESTED,
  ANNOUNCEMENT_IMAGE_READ_REQUESTED,
  ADD_ANNOUNCEMENT_REQUESTED,
  DELETE_ANNOUNCEMENT_REQUESTED,
  APPROVE_ANNOUNCEMENTS_REQUESTED,
  //GET_DESKTOP_IMAGE_URI_REQUESTED,
  //GET_MOBILE_IMAGE_URI_REQUESTED,
  //GET_TABLET_IMAGE_URI_REQUESTED,
  IGetAnnouncementsPayload,
  IApproveAnnouncementsPayload,
  IAddAnnouncementPayload,
  ISaveAnnouncementPayload,
  IDeleteAnnouncementPayload,
  IAnnouncementImagePreviewPayload,
  IAnnouncementImageReadPreviewPayload,
//  IGetDesktopImagePayload,
//  IGetMobileImagePayload,
//  IGetTabletImagePayload,
} from './types'
import { SagaIterator } from '@redux-saga/core'
import { PayloadAction } from '@reduxjs/toolkit'
import { call, takeEvery, put } from 'redux-saga/effects'
import { getConfig } from '../../../OpsConfig'
import { getApiErrorAction } from '../../utility/errorHandling'

// NOT USED
//export const NotAvailableMessage: IMessage = {
//  message:
//    'Common Solutions editing is temporarily unavailable.  Please try again in several minutes.',
//  type: ElxMessageBarType.blocked,
//  isMultiline: false,
//}

function* getAnnouncementsList(
  action: PayloadAction<IScopedPayload & IGetAnnouncementsPayload>,
): SagaIterator {
  try {
    const config = yield call(getConfig)
    const announcementsPath = `${config.apiBaseURL
      }/EditAnnouncements/GetHomePageAnnouncements`
    const announcements = yield call(Http.get, action, announcementsPath)
    yield put({
      type: 'GET_ANNOUNCEMENTS_SUCCESS',
      announcementList: announcements.data,
    })
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_ANNOUNCEMENTS_FAILURE'));
    Logger.error(err)
  } finally {
  }
}

function* saveAnnouncement(
  action: PayloadAction<IScopedPayload & ISaveAnnouncementPayload>,
): SagaIterator {
  const editedAnnouncement = action.payload.announcement;
  try {
    const config = yield call(getConfig);
    let announcementsPath = `${
      config.apiBaseURL
    }/EditAnnouncements/UpsertHomePageAnnouncement`
    yield call(Http.post, action, announcementsPath, editedAnnouncement)
    yield put({
      type: 'SAVE_ANNOUNCEMENT_SUCCESS',
    })
  } catch (err) {
    yield put(getApiErrorAction(err, 'SAVE_ANNOUNCEMENT_FAILURE'));
    Logger.error(err)
  } finally {
  }
}

function* announcementImagesPreview(
  action: PayloadAction<IScopedPayload & IAnnouncementImagePreviewPayload>,
): SagaIterator {
  const announcementImageIdList: Array<string> = action.payload.imageIdList;
  try {
    const config = yield call(getConfig);
    let announcementsPath = `${
      config.apiBaseURL
    }/EditAnnouncements/GetAnnouncementsDataWithImageUriByAnnouncementIds`
    const images = yield call(
      Http.post,
      action,
      announcementsPath,
      announcementImageIdList,
    )
    yield put({
      type: 'ANNOUNCEMENT_IMAGES_SUCCESS',
      imageDetails: images.data,
    })
  } catch (err) {
    yield put(getApiErrorAction(err, 'ANNOUNCEMENT_IMAGES_FAILURE'));
    Logger.error(err)
  } finally {
  }
}

function* announcementImageReadPreview(
  action: PayloadAction<IScopedPayload & IAnnouncementImageReadPreviewPayload>,
): SagaIterator {
  const imageName = action.payload.imageName
  try {
    const config = yield call(getConfig);
    let announcementsPath = `${
      config.apiBaseURL
      }/EditAnnouncements/GetHomePageAnnouncementsReadUri?fileName=${imageName}`
    const image = yield call(
      Http.post,
      action,
      announcementsPath,
      imageName,
    )
    yield put({
      type: 'ANNOUNCEMENT_IMAGE_READ_SUCCESS',
      imageURL: image.data,
    })
  } catch (err) {
    yield put(getApiErrorAction(err, 'ANNOUNCEMENT_IMAGE_READ_FAILURE'));
    Logger.error(err)
  } finally {
  }
}

function* addAnnouncement(
  action: PayloadAction<IScopedPayload & IAddAnnouncementPayload>,
): SagaIterator {
  const newAnnouncementData = action.payload.newAnnouncement;
  try {
    const config = yield call(getConfig);
    let announcementsPath = `${
      config.apiBaseURL
    }/EditAnnouncements/CreateHomePageAnnouncementsEntityModel`

    const updatedAnnouncement = yield call(
      Http.post,
      action,
      announcementsPath,
      newAnnouncementData,
    )
    yield put({
      type: 'ADD_ANNOUNCEMENT_SUCCESS',
      newAnnouncementDetail: updatedAnnouncement.data,
    })
  } catch (err) {
    yield put(getApiErrorAction(err, 'ADD_ANNOUNCEMENT_FAILURE'));
    Logger.error(err)
  } finally {
  }
}

function* deleteAnnouncement(
  action: PayloadAction<IScopedPayload & IDeleteAnnouncementPayload>,
): SagaIterator {
  const id = action.payload.announcementId
  try {
    const config = yield call(getConfig);
    let announcementsPath = `${
      config.apiBaseURL
    }/EditAnnouncements/DeleteHomePageAnnouncement?announcementId=${id}`
    yield call(Http.post, action, announcementsPath)
    yield put({
      type: 'DELETE_ANNOUNCEMENT_SUCCESS',
      announcementId: id
    })
  } catch (err) {
    yield put(getApiErrorAction(err, 'DELETE_ANNOUNCEMENT_FAILURE'));
    Logger.error(err)
  } finally {
  }
}

function* approveAnnouncements(
  action: PayloadAction<IScopedPayload & IApproveAnnouncementsPayload>,
): SagaIterator {
  const announcementIdList = action.payload.announcementIdList
  try {
    const config = yield call(getConfig);
    let announcementsPath = `${config.apiBaseURL
      }/EditAnnouncements/ApproveAnnouncementsByIds`
    yield call(Http.post, action, announcementsPath, announcementIdList)
    yield put({
      type: 'APPROVE_ANNOUNCEMENT_SUCCESS',
    })
  } catch (err) {
    yield put(getApiErrorAction(err, 'APPROVE_ANNOUNCEMENT_FAILURE'));
    Logger.error(err)
  } finally {
  }
}

// NOT USED
//function* getDesktopImage(
//  action: PayloadAction<IScopedPayload & IGetDesktopImagePayload>,
//): SagaIterator {
//  const imageName = action.payload.imageName
//  const imageFile = action.payload.image
//  try {
//    const config = yield call(getConfig);
//    let imagePath = `${
//      config.apiBaseURL
//    }/EditAnnouncements/GetHomePageAnnouncementsUploadUri?fileName=${imageName}`
//    const desktopImageURL = yield call(Http.get, action, imagePath)
//    var imageURL = desktopImageURL.data
//    yield call(Http.put, action, imageURL, imageFile)
//  } catch (err) {
//    yield put(getApiErrorAction(err, 'GET_DESKTOP_IMAGE_FAILURE'));
//    Logger.error(err)
//  } finally {
//  }
//}
//
//function* getTabletImage(
//  action: PayloadAction<IScopedPayload & IGetTabletImagePayload>,
//): SagaIterator {
//  const imageName = action.payload.imageName
//  const imageFile = action.payload.image
//  try {
//    const config = yield call(getConfig);
//    let imagePath = `${
//      config.apiBaseURL
//    }/EditAnnouncements/GetHomePageAnnouncementsUploadUri?fileName=${imageName}`
//    const tabletImageURL = yield call(Http.get, action, imagePath)
//    var imageURL = tabletImageURL.data
//    yield call(Http.put, action, imageURL, imageFile)
//  } catch (err) {
//    yield put(getApiErrorAction(err, 'GET_TABLET_IMAGE_FAILURE'));
//    Logger.error(err)
//  } finally {
//  }
//}
//
//function* getMobileImage(
//  action: PayloadAction<IScopedPayload & IGetMobileImagePayload>,
//): SagaIterator {
//  const imageName = action.payload.imageName
//  const imageFile = action.payload.image
//  try {
//    const config = yield call(getConfig);
//    let imagePath = `${
//      config.apiBaseURL
//    }/EditAnnouncements/GetHomePageAnnouncementsUploadUri?fileName=${imageName}`
//    const mobileImageURL = yield call(Http.get, action, imagePath)
//    var imageURL = mobileImageURL.data
//    yield call(Http.put, action, imageURL, imageFile)
//  } catch (err) {
//    yield put(getApiErrorAction(err, 'GET_MOBILE_IMAGE_FAILURE'));
//    Logger.error(err)
//  } finally {
//  }
//}

export function* AnnouncementsSagas(): Generator {
  yield takeEvery(
    GET_ANNOUNCEMENTS_REQUESTED,
    containerManagedAction(getAnnouncementsList),
  );

  yield takeEvery(
    SAVE_ANNOUNCEMENT_REQUESTED,
    containerManagedAction(saveAnnouncement),
  );

  yield takeEvery(
    ANNOUNCEMENT_IMAGES_REQUESTED,
    containerManagedAction(announcementImagesPreview),
  );

  yield takeEvery(
    ANNOUNCEMENT_IMAGE_READ_REQUESTED,
    containerManagedAction(announcementImageReadPreview),
  );

  yield takeEvery(
    APPROVE_ANNOUNCEMENTS_REQUESTED,
    containerManagedAction(approveAnnouncements),
  );

  yield takeEvery(
    ADD_ANNOUNCEMENT_REQUESTED,
    containerManagedAction(addAnnouncement),
  );

  yield takeEvery(
    DELETE_ANNOUNCEMENT_REQUESTED,
    containerManagedAction(deleteAnnouncement),
  );

//  yield takeEvery(GET_DESKTOP_IMAGE_URI_REQUESTED, getDesktopImage);
//  yield takeEvery(GET_MOBILE_IMAGE_URI_REQUESTED, getMobileImage);
//  yield takeEvery(GET_TABLET_IMAGE_URI_REQUESTED, getTabletImage);
}
export default AnnouncementsSagas
