import {
	ElxSearchBox,
	ISearchCriteria,
	isNullOrWhitespace,
	SearchBoxSize,
} from '@elixir/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSearchResults } from '../store/action';
import '../styling/styles.css';
import { IScope } from '@elixir/fx';

function SearchOperations(props: { scope: IScope; OpsType: string }) {
	const { OpsType } = props;
	const dispatch = useDispatch();
	const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
		keyword: '',
		filters: {},
	});
	const [searchCriteria2, setSearchCriteria2] = useState<ISearchCriteria>({
		keyword: '',
		filters: {},
	});

	// There doesn't appear to be any explicit event associated with the Clear button
	// other than onChange which also gets fired on each keystroke.  So, the only way
	// I can think to clear the results is to have two handles, one for onSearch,
	// which is fired when Enter is pressed, and onChange, which will only do something
	// if the keyword is blank and the previous keyword was non-blank.
	useEffect(() => {
			dispatch(getSearchResults(searchCriteria.keyword, OpsType, props.scope));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchCriteria.keyword, dispatch, OpsType]);

	useEffect(() => {
		if (isNullOrWhitespace(searchCriteria2.keyword) && !isNullOrWhitespace(searchCriteria.keyword)) {
			setSearchCriteria({ keyword: '', filters: {} });
		}
	}, [searchCriteria2.keyword, searchCriteria.keyword]);

	return (
		<div>
			<ElxSearchBox
				size={SearchBoxSize.Medium}
				onSearch={setSearchCriteria}
				onChange={setSearchCriteria2}
				placeholder='SEARCH'
			/>
		</div>
	);
}
export default SearchOperations;
