import { Http, containerManagedAction, IScopedPayload } from '@elixir/fx';
import { isNullOrWhitespace } from '@elixir/components';
import {
  CLEAR_LOD_REQUESTED,
  CUSTOMER_ADD_ACCESS_REQUESTED,
  CUSTOMER_REMOVE_ACCESS_REQUESTED,
  WORKSPACE_REMOVE_MSA_REQUESTED,
  EMAIL_VERIFICATION_REQUESTED,
  GET_CUSTOMER_WORKSPACES_REQUESTED,
  GET_SEARCH_RESULTS_REQUESTED,
  GET_ASSESSMENT_REQUESTED,
  GET_USER_ENTITLEMENTS_REQUESTED,
  GET_SHOW_ROLES_REQUESTED,
  ICustomerWorkspacesPayload,
  ISearchResultsPayload,
  IAssessmentOverridePayload,
  IAssessmentPayload,
  IClearLODPayload,
  ICustomerAddAccessPayload,
  ICustomerRemoveAccessPayload,
  IEmailPayload,
  IShowRolesPayload,
  IUserEntitlementsPayload,
  IWorkspaceAddAccessPayload,
  IWorkspaceRemoveAccessPayload,
  OVERRIDE_ASSESSMENT_REQUESTED,
  WORKSPACE_ADD_ACCESS_REQUESTED,
  WORKSPACE_REMOVE_ACCESS_REQUESTED,
  WORKSPACE_ADD_MSA_REQUESTED,
  IWorkspaceAddMsaPayload,
  IWorkspaceRemoveMsaPayload,
} from './types';
import { SagaIterator } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, put } from 'redux-saga/effects';
import { getConfig } from '../../../OpsConfig';
import { getApiErrorAction, ErrorType, IError } from '../../utility/errorHandling';
import { Logger } from '@elixir/telemetry';

// NOT USED
//export const NotAvailableMessage: IMessage = {
//  message:
//    'Common Solutions editing is temporarily unavailable.  Please try again in several minutes.',
//  type: ElxMessageBarType.blocked,
//  isMultiline: false,
//};

function* getSearchResults(
  action: PayloadAction<IScopedPayload & ISearchResultsPayload>,
): SagaIterator {
  yield put({
    type: 'GET_SEARCH_RESULTS_SUCCESS',
    payload: {},
    allresults: {},
  });
  try {
    const id = action.payload.id;
    const OpsType = action.payload.OpsType;
    if (!isNullOrWhitespace(id)) {
      const config = yield call(getConfig);
      let operationsPath = `${config.apiBaseURL}/opsSearch?query=${id}&maxItems=300&skip=0&documentTypeFilters=${OpsType}`;
      const allresults = yield call(Http.get, action, operationsPath);
      yield put({
        type: 'GET_SEARCH_RESULTS_SUCCESS',
        allresults: allresults.data,
      });
    }
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_SEARCH_RESULTS_FAILURE'));
    Logger.error(err);
  }
}
function* getCustomerWorkspaceSearchResults(
  action: PayloadAction<IScopedPayload & ICustomerWorkspacesPayload>,
): SagaIterator {
  //yield put(uxClearContainerMessageAction(action.payload.scope));
  yield put({ type: 'GET_CUSTOMER_WORKSPACES_SUCCESS', payload: {}, workspaceList: {} });
  try {
    const name = action.payload.name;
    const model = {
      query: action.payload.name,
      maxItems: 300,
      skip: 0,
      customerId: action.payload.id,
      workspaceIds: action.payload.workspaceIds,
    };
    if (!isNullOrWhitespace(name)) {
      const config = yield call(getConfig);
      let operationsPath = `${config.apiBaseURL}/opsSearch/GetSearchResultsByCustomer`;
      const workspaceList = yield call(Http.post, action, operationsPath, model);
      yield put({
        type: 'GET_CUSTOMER_WORKSPACES_SUCCESS',
        workspaceList: workspaceList.data,
      });
    }
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_CUSTOMER_WORKSPACES_FAILURE'));
    Logger.error(err);
  }
}
function* getEmailVerificationForUser(
  action: PayloadAction<IScopedPayload & IEmailPayload>,
): SagaIterator {
  //yield put(uxClearContainerMessageAction(action.payload.scope));
  try {
    const config = yield call(getConfig);
    const alias = action.payload.userAlias;
    let operationsPath = `${config.apiBaseURL}/opsSearch?query=${alias}&maxItems=300&skip=0&documentTypeFilters=emailverification`;

    const emailJson = yield call(Http.get, action, operationsPath);
    yield put({
      type: 'EMAIL_VERIFICATION_SUCCESS',
      emailJson: emailJson.data,
    });
  } catch (err) {
    yield put(getApiErrorAction(err, 'EMAIL_VERIFICATION_FAILURE'));
    Logger.error(err);
  }
}
function* getUserEntitlements(
  action: PayloadAction<IScopedPayload & IUserEntitlementsPayload>,
): SagaIterator {
  try {
    const config = yield call(getConfig);
    const userId = action.payload.userId;
    const entitlementType = action.payload.entitlementType;
    let entitlementPath = `${config.apiBaseURL}/Entitlement/GetSupportEntitlementsForUserAsync?userId=${userId}&isForCommercial=${entitlementType}&locale=en-us`;
    const entitlement = yield call(Http.get, action, entitlementPath);
    yield put({
      type: 'GET_USER_ENTITLEMENTS_SUCCESS',
      entitlement: entitlement.data,
    });
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_USER_ENTITLEMENTS_FAILURE'));
    Logger.error(err);
  }
}
function* getShowRoles(action: PayloadAction<IScopedPayload & IShowRolesPayload>): SagaIterator {
  try {
    const config = yield call(getConfig);
    const userId = action.payload.userId;
    let rolesPath = `${config.apiBaseURL}/RoleAndClaim/GetRolesAndClaimsForUserId?userId=${userId}`;
    const showRoles = yield call(Http.get, action, rolesPath);
    yield put({
      type: 'GET_SHOW_ROLES_SUCCESS',
      showRoles: showRoles.data,
    });
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_SHOW_ROLES_FAILURE'));
    Logger.error(err);
  }
}

//TODO: Track ClearLOD state across request and success
function* clearLodIdsByUserId(
  action: PayloadAction<IScopedPayload & IClearLODPayload>,
): SagaIterator {
  try {
    const lodId = action.payload.lodId;
    const config = yield call(getConfig);
    let lodPath = `${config.apiBaseURL}/User/ClearLodExternalSystemIds?userId=${lodId}`;
    const roles = yield call(Http.post, action, lodPath);
    if (roles.status === 200)
      yield put({
        type: 'CLEAR_LOD_SUCCESS',
      });
    else
      yield put({
        type: 'CLEAR_LOD_FAILURE',
        error: {
          errorType: ErrorType.Other,
          errorMessage: 'An unexpected error occurred.  LOD was not cleared.',
        } as IError,
      });
  } catch (err) {
    yield put(getApiErrorAction(err, 'CLEAR_LOD_FAILURE'));
    Logger.error(err);
  }
}

//TODO: Track success state; ensure success message is displayed from UI component
function* addUserToWorkspace(
  action: PayloadAction<IScopedPayload & IWorkspaceAddAccessPayload>,
): SagaIterator {
  try {
    const config = yield call(getConfig);
    const id = action.payload.id;
    let WorkspaceAccessPath = `${config.apiBaseURL}/User/AddCurrentOpsUserToWorkspace?workspaceId=${id}`;
    const response = yield call(Http.post, action, WorkspaceAccessPath);
    if (response?.status === 200)
      yield put({
        type: 'WORKSPACE_ADD_ACCESS_SUCCESS',
        id,
      });
    else
      yield put({
        type: 'WORKSPACE_ADD_ACCESS_FAILURE',
        error: {
          errorType: ErrorType.Other,
          errorMessage: `An unexpected error occurred.  Access was NOT granted for workspace with id ${id}.`,
        } as IError,
      });
  } catch (err) {
    yield put(getApiErrorAction(err, 'WORKSPACE_ADD_ACCESS_FAILURE'));
    Logger.error(err);
  }
}

//TODO: Track success state; ensure success message is displayed from UI component
function* removeUserFromWorkspace(
  action: PayloadAction<IScopedPayload & IWorkspaceRemoveAccessPayload>,
): SagaIterator {
  const id = action.payload.id;
  try {
    const config = yield call(getConfig);
    let WorkspaceRemovalPath = `${config.apiBaseURL}/User/RemoveCurrentUserFromWorkspace?workspaceId=${id}`;
    const response = yield call(Http.post, action, WorkspaceRemovalPath);
    if (response?.status === 200)
      yield put({
        type: 'WORKSPACE_REMOVE_ACCESS_SUCCESS',
        id,
      });
    else
      yield put({
        type: 'WORKSPACE_REMOVE_ACCESS_FAILURE',
        error: {
          errorType: ErrorType.Other,
          errorMessage: `An unexpected error occurred.  Access was NOT revoked for workspace with id ${id}.`,
        } as IError,
      });
  } catch (err) {
    yield put(getApiErrorAction(err, 'WORKSPACE_REMOVE_ACCESS_FAILURE'));
    Logger.error(err);
  }
}

//TODO: Track success state; ensure success message is displayed from UI component
function* addUserToCustomer(
  action: PayloadAction<IScopedPayload & ICustomerAddAccessPayload>,
): SagaIterator {
  try {
    const config = yield call(getConfig);
    const id = action.payload.id;
    let CustomerAddAccessPath = `${config.apiBaseURL}/User/AddCurrentOpsUserToCustomer?customerId=${id}`;
    const response = yield call(Http.post, action, CustomerAddAccessPath);
    if (response?.status === 200)
      yield put({
        type: 'CUSTOMER_ADD_ACCESS_SUCCESS',
        id,
        idForCustomerAccess: response.data,
      });
    else
      yield put({
        type: 'CUSTOMER_ADD_ACCESS_FAILURE',
        error: {
          errorType: ErrorType.Other,
          errorMessage: `An unexpected error occurred.  Access was NOT granted for customer with id ${id}.`,
        } as IError,
      });
  } catch (err) {
    yield put(getApiErrorAction(err, 'CUSTOMER_ADD_ACCESS_FAILURE'));
    Logger.error(err);
  }
}

//TODO: Track success state; ensure success message is displayed from UI component
function* removeUserFromCustomer(
  action: PayloadAction<IScopedPayload & ICustomerRemoveAccessPayload>,
): SagaIterator {
  const id = action.payload.id;
  try {
    const config = yield call(getConfig);
    let CustomerRemoveAccessPath = `${config.apiBaseURL}/User/RemoveCurrentUserFromCustomer?customerId=${id}`;
    const response = yield call(Http.post, action, CustomerRemoveAccessPath);
    if (response?.status === 200)
      yield put({
        type: 'CUSTOMER_REMOVE_ACCESS_SUCCESS',
        id,
      });
    else
      yield put({
        type: 'CUSTOMER_REMOVE_ACCESS_FAILURE',
        error: {
          errorType: ErrorType.Other,
          errorMessage: `An unexpected error occurred.  Access was NOT revoked for customer with id ${id}.`,
        } as IError,
      });
  } catch (err) {
    yield put(getApiErrorAction(err, 'CUSTOMER_REMOVE_ACCESS_FAILURE'));
    Logger.error(err);
  }
}

function* addMsaToWorkspace(
  action: PayloadAction<IScopedPayload & IWorkspaceAddMsaPayload>,
): SagaIterator {
  try {
    const config = yield call(getConfig);
    const id = action.payload.id;
    let WorkspaceAccessPath = `${config.apiBaseURL}/opsSearch/AddMsaException?workspaceId=${id}`;
    const response = yield call(Http.post, action, WorkspaceAccessPath);
    if (response?.status === 200)
      yield put({
        type: 'WORKSPACE_ADD_MSA_SUCCESS',
        id,
      });
    else
      yield put({
        type: 'WORKSPACE_ADD_MSA_FAILURE',
        error: {
          errorType: ErrorType.Other,
          errorMessage: `An unexpected error occurred.  MSA exception was NOT added for workspace with id ${id}.`,
        } as IError,
      });
  } catch (err) {
    yield put(getApiErrorAction(err, 'WORKSPACE_ADD_MSA_FAILURE'));
    Logger.error(err);
  }
}

function* removeMsaFromWorkspaces(
  action: PayloadAction<IScopedPayload & IWorkspaceRemoveMsaPayload>,
): SagaIterator {
  try {
    const config = yield call(getConfig);
    const id = action.payload.id;
    let WorkspaceAccessPath = `${config.apiBaseURL}/opsSearch/RemoveMsaException?workspaceId=${id}`;
    const response = yield call(Http.post, action, WorkspaceAccessPath);
    if (response?.status === 200)
      yield put({
        type: 'WORKSPACE_REMOVE_MSA_SUCCESS',
        id,
      });
    else
      yield put({
        type: 'WORKSPACE_REMOVE_MSA_FAILURE',
        error: {
          errorType: ErrorType.Other,
          errorMessage: `An unexpected error occurred.  MSA exception was NOT removed from workspace with id ${id}.`,
        } as IError,
      });
  } catch (err) {
    yield put(getApiErrorAction(err, 'WORKSPACE_REMOVE_MSA_FAILURE'));
    Logger.error(err);
  }
}

function* getAssessment(action: PayloadAction<IScopedPayload & IAssessmentPayload>): SagaIterator {
  const workspaceId = action.payload.workspaceId;
  try {
    const config = yield call(getConfig);
    let assessmentPath = `${config.apiBaseURL}/Entitlement/GetAssessmentEntitlementOverrideWExpiry?workspaceId=${workspaceId}`;
    const assessmentData = yield call(Http.get, action, assessmentPath);
    yield put({
      type: 'GET_ASSESSMENT_SUCCESS',
      assessments: assessmentData.data,
    });
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_ASSESSMENT_FAILURE'));
    Logger.error(err);
  }
}
function* overrideAssessment(
  action: PayloadAction<IScopedPayload & IAssessmentOverridePayload>,
): SagaIterator {
  const workspaceId = action.payload.workspaceId;
  const assessmentJson = action.payload.overrideJson;
  try {
    const config = yield call(getConfig);
    let assessmentPath = `${config.apiBaseURL}/Entitlement/SetAssessmentEntitlementOverrideWExpiry?workspaceId=${workspaceId}`;
    const response = yield call(Http.post, action, assessmentPath, assessmentJson);
    if (response?.status === 200)
      yield put({
        type: 'OVERRIDE_ASSESSMENT_SUCCESS',
      });
    else
      yield put({
        type: 'OVERRIDE_ASSESSMENT_FAILURE',
        error: {
          errorType: ErrorType.Other,
          errorMessage: 'An unexpected error occurred.',
        } as IError,
      });
  } catch (err) {
    yield put(getApiErrorAction(err, 'OVERRIDE_ASSESSMENT_FAILURE'));
    Logger.error(err);
  }
}

export function* OperationSagas(): Generator {
  yield takeLatest(GET_SEARCH_RESULTS_REQUESTED, containerManagedAction(getSearchResults));
  yield takeLatest(
    GET_CUSTOMER_WORKSPACES_REQUESTED,
    containerManagedAction(getCustomerWorkspaceSearchResults),
  );
  yield takeLatest(GET_USER_ENTITLEMENTS_REQUESTED, containerManagedAction(getUserEntitlements));
  yield takeLatest(GET_SHOW_ROLES_REQUESTED, containerManagedAction(getShowRoles));
  yield takeLatest(CLEAR_LOD_REQUESTED, containerManagedAction(clearLodIdsByUserId));
  yield takeLatest(WORKSPACE_ADD_ACCESS_REQUESTED, containerManagedAction(addUserToWorkspace));
  yield takeLatest(
    WORKSPACE_REMOVE_ACCESS_REQUESTED,
    containerManagedAction(removeUserFromWorkspace),
  );
  yield takeLatest(CUSTOMER_ADD_ACCESS_REQUESTED, containerManagedAction(addUserToCustomer));
  yield takeLatest(
    CUSTOMER_REMOVE_ACCESS_REQUESTED,
    containerManagedAction(removeUserFromCustomer),
  );
  yield takeLatest(WORKSPACE_ADD_MSA_REQUESTED, containerManagedAction(addMsaToWorkspace));
  yield takeLatest(WORKSPACE_REMOVE_MSA_REQUESTED, containerManagedAction(removeMsaFromWorkspaces));
  yield takeLatest(GET_ASSESSMENT_REQUESTED, containerManagedAction(getAssessment));
  yield takeLatest(OVERRIDE_ASSESSMENT_REQUESTED, containerManagedAction(overrideAssessment));
  yield takeLatest(
    EMAIL_VERIFICATION_REQUESTED,
    containerManagedAction(getEmailVerificationForUser),
  );
}
export default OperationSagas;
