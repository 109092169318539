import * as ReactDOM from 'react-dom';
import { setConfig, IElxConfig } from '@elixir/fx';
import { InteractionType, LoginType } from '@elixir/auth';
import { getConfig, IConfig } from './OpsConfig';
import { OpsSite } from './app';

getConfig().then(async (config: IConfig) => {
  const operationCenterConfig: Partial<IElxConfig> = {
    applicationTitle: config.applicationTitle,
    applicationIconName: config.applicationIconName,
    baseUrl: config.baseUrl,
    authConfig: {
      authentication: config.authentication,
      tenantId: config.tenantId,
      clientId: config.clientId,
      authorityURL: config.msalAuthUrl,
      loginType: LoginType.Redirect,
      interactionType: InteractionType.Redirect,
      scopes: config.scopes,
      restrictExternalUsers: config.restrictExternalUsers,
    },
  };

  setConfig(operationCenterConfig);

  ReactDOM.render(<OpsSite />, document.getElementById('root'));
});
