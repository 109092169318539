import '../../../styling/css/style.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStackTokens, mergeStyles, IStackItemStyles, Link } from '@fluentui/react';
import { isEmpty, isEqual } from 'lodash';
import { DocumentCard, DocumentCardTitle } from '@fluentui/react/lib/DocumentCard';
import { Stack } from '@fluentui/react';
import JSONPretty from 'react-json-pretty';
import { useLocation } from 'react-router';
import { IScope, uxAddContainerMessageAction, uxClearContainerMessageAction } from '@elixir/fx';

import {
  ElxCopy,
  ElxIconButton,
  ElxPanel,
  ElxSecondaryButton,
  IMessage,
  isNullOrWhitespace,
  ElxMessageBarType,
  PanelSize,
} from '@elixir/components';

import { getSearchResults } from '../store/action';
import { IRegistrationColumns, IRegistrationRolesColumns } from '../store/types';
import { ErrorType, IError } from '../../utility/errorHandling';
import { PermissionErrorMessage } from '../../utility/PermissionErrorMessage';
import { getCurrentThemeFromStorage, darkTheme, lightTheme } from '../../../app';

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: 5,
    cursor: 'Pointer',
    flex: 1,
  },
};

export const cardContentStackStyles = mergeStyles({
  width: 400,
});
export const cardContentStackStyles2 = mergeStyles({
  width: 300,
});
export const cardContentStackTokens: IStackTokens = {
  childrenGap: 'l1',
};

interface IPanelExampleState {
  show: boolean;
  size: PanelSize;
  message?: IMessage;
  isLoading: boolean;
  fillBackground: boolean;
}

export const Registration = (props: { scope: IScope; OpsType: string }) => {
  const theme = getCurrentThemeFromStorage(localStorage);
  const regColumn: IRegistrationColumns[] = [];
  const exclusionColumns = ['ColumnRoles', 'ColumnJson'];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const Id = queryParams.get('id') ?? '';
  const dispatch = useDispatch();

	// Subscribe for data changes
  const error: IError | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.error
  );
  const registration: { [key: string]: Array<any> } | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.allresults,
  );

  // Setup state variables
  const [copyJSON, setCopyJSON] = React.useState<string>('');
  const [panelData, setPanelData] = useState('');
  const [panelState, setPanelState] = React.useState<IPanelExampleState>({
    show: false,
    size: PanelSize.small,
    isLoading: false,
    fillBackground: false,
  });
  const { show, size, isLoading, fillBackground, message } = panelState;

  // Setup event handlers
  function openPanel(
    data: any,
    size: PanelSize,
    fillBackground: boolean,
    setPanelState: React.Dispatch<React.SetStateAction<IPanelExampleState>>,
  ): void {
    setPanelData(data);
    setPanelState(state => ({
      ...state,
      show: true,
      size,
      fillBackground,
    }));
  }

  // Search for registrations
  useEffect(() => {
    if (!isNullOrWhitespace(Id)) {
      dispatch(getSearchResults(Id, props.OpsType, props.scope));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Do something with any returned data

	// Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: (
                <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />
              )
            },
            props.scope)
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.'
            },
            props.scope)
        );
      }
    } else {
      // Clear any existing error message
      dispatch(uxClearContainerMessageAction(props.scope));
    }
  });

  // Check if there is no registration data to display
  if (
    registration === undefined || isEqual(registration, {}) || isEmpty(registration.results) ||
    !registration.results.some((r: any) => r.documentType === props.OpsType.toLowerCase())) {
    return (
      <div className="default-contract">
        <b style={{ color: '#605E5C', letterSpacing: 2 }}>
          THERE ARE NO RESULTS TO DISPLAY. START SEARCHING !{' '}
        </b>
      </div>
    );
  }
  
  // Otherwise, display registration data
  else {
    if (registration?.results) {
      // eslint-disable-next-line array-callback-return
      registration?.results?.map((x: any) => {
        let LastUpdated = 'UNK';
        if (x?.item.LastUpdated?.Date != null) {
          LastUpdated = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          }).format(new Date(x.item.LastUpdated.Date));
        }

        let CreatedDate = 'UNK';
        if (x?.item.Created?.Date != null) {
          CreatedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          }).format(new Date(x.item.Created.Date));
        }

        const roleAssign: Array<IRegistrationRolesColumns> = [];
        if (x?.item?.RoleAssignments) {
          // eslint-disable-next-line array-callback-return
          x?.item?.RoleAssignments?.map((role: IRegistrationRolesColumns, index: number) => {
            roleAssign.push(role);
          });
        }

        const regJSON = {
          RegistrationId: x.item.Id,
          'CREATED BY ID': x.item?.CreatedById ?? 'N/A',
          'CREATED DATE': CreatedDate ?? 'N/A',
          'LAST UPDATED': LastUpdated ?? 'N/A',
          'RECIPIENT EMAIL': x.item?.RecipientEmail ?? 'N/A',
          'WORKSPACE ID': x.item?.WorkspaceId ?? 'N/A',
          'REDEEMED BY': x.item?.RedeemedBy ?? 'N/A',
          'REDEEMED DATE': x.item?.RedeemedDate?.Date ?? 'N/A',
          'LAST INVITE SENT': x.item?.LastInviteSent?.Date ?? 'N/A',
          'REVOKED DATE': x.item?.RevokedDate?.Date ?? 'N/A',
          'REVOKED BY': x.item?.RevokedBy ?? 'N/A',
          ColumnRoles: roleAssign,
          ColumnJson: x?.item ?? 'N/A',
        };

        regColumn.push(regJSON);
      });
    }

    var JSONPrettyMon = require('react-json-pretty/dist/monikai');
    let keyIndex = 0;
    
    return (
      <div style={{ padding: 20 }}>
        <ElxPanel
          headerText="REGISTRATION JSON"
          isOpen={show}
          size={size}
          fillBackground={fillBackground}
          message={message}
          isLoading={isLoading}
          onDismiss={() => setPanelState({ ...panelState, show: false })}>
          <div className="contract-modal">
            <ElxCopy
              textToCopy={JSON.stringify(panelData, null, 2)}
              onClick={() => setCopyJSON(copyJSON)}>
              <ElxIconButton text="Copy" iconProps={{ iconName: 'Info' }} />
            </ElxCopy>
            <JSONPretty data={panelData} theme={JSONPrettyMon} style={{ padding: 10 }}></JSONPretty>
          </div>
        </ElxPanel>
        {
          // eslint-disable-next-line array-callback-return
          regColumn.map((column: IRegistrationColumns, index: number) => {
            const columnId = column['RegistrationId'];
            return (
              <div key={`${columnId}-${index}-inc-${keyIndex++}`}>
                <DocumentCard className="sub-card-styles-packages">
                  <Stack horizontal>
                    <Stack.Item align="start" styles={stackItemStyles}>
                      <DocumentCardTitle
                        title="REGISTRATION"
                        className="user-title"
                        shouldTruncate
                      />
                    </Stack.Item>
                    <Stack.Item align="end">
                      <ElxSecondaryButton
                        text="SHOW REGISTRATION JSON"
                        title="json"
                        className="json-button"
                        onClick={() =>
                          openPanel(column['ColumnJson'], PanelSize.large, false, setPanelState)
                        }
                      />
                    </Stack.Item>
                  </Stack>

                  <Stack horizontal tokens={cardContentStackTokens} wrap>
                    {Object.entries(column)
                      .filter(([key, val]) => !exclusionColumns.includes(key))
                      .map(([key, value], columnIndex: number) => {
                        const link = `${
                          key === 'RegistrationId' ? 'Registration' : 'User'
                        }?id=${value}`;
                        return key !== 'RegistrationId' && key !== 'CREATED BY ID' ? (
                          <Stack
                            className={cardContentStackStyles}
                            key={`${columnId}-stack-${columnIndex}-inc-${keyIndex++}`}>
                            <Stack.Item>
                              <b>{key}</b>: {value}
                            </Stack.Item>
                          </Stack>
                        ) : (
                          <Stack
                            className={cardContentStackStyles}
                            key={`${columnId}-stackLink-${columnIndex}-inc-${keyIndex++}`}>
                            <Stack.Item>
                              <b>{key}</b>:&nbsp;
                              <Link
                                href={`${link}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  color:
                                    theme === 'darkTheme'
                                      ? darkTheme.semanticColors.link
                                      : lightTheme.semanticColors.link,
                                  fontSize: 14,
                                  fontWeight: 600,
                                }}>
                                {value.toUpperCase()}
                              </Link>{' '}
                            </Stack.Item>
                          </Stack>
                        );
                      })}
                  </Stack>
                  <br />
                  <b>ROLE ASSIGNMENTS: </b>
                  <br />
                  <Stack horizontal wrap>
                    {column['ColumnRoles'].map((value: IRegistrationRolesColumns) => {
                      return (
                        <Stack
                          horizontal
                          tokens={cardContentStackTokens}
                          wrap
                          key={`${columnId}-${index}-role-${keyIndex++}`}>
                          <Stack className={cardContentStackStyles2}>
                            <Stack.Item>{`${value.Value.toUpperCase()}  `}</Stack.Item>
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                </DocumentCard>
              </div>
            );
          })
        }
      </div>
    );
  }
};

export default Registration;
