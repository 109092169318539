import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getContacts } from '../../../store/action';
import '../../../styling/css/style.css';
import { IScope, uxAddContainerMessageAction, uxClearContainerMessageAction } from '@elixir/fx';
import {
  ElxMessageBarType,
  ElxTableContainer,
  IElxColumn,
  IElxContainerProps,
  IElxSearchBoxProps,
} from '@elixir/components';
import { ErrorType, IError } from '../../../../utility/errorHandling';
import { PermissionErrorMessage } from '../../../../utility/PermissionErrorMessage';
import { isEmpty, isEqual } from 'lodash';
import { IContactColumns } from '../../../store/types';
import { SelectionMode } from '@fluentui/react';

export const Contacts = (props: {
  packageID: string;
  scope: IScope;
  searchProps?: IElxSearchBoxProps;
  containerProps?: IElxContainerProps;
}) => {
  const searchBoxProps = {
    ...props.searchProps,
  };

  const contactColumns: IContactColumns[] = [];

  const dispatch = useDispatch();

  // Subscribe for data changes
  const error: IError | undefined = useSelector((state: any) => state?.modules?.Contract?.error);
  const contacts: Array<Object> = useSelector((state: any) => state?.modules?.Contract?.contacts);

  // Get contacts for current Contract
  useEffect(() => {
    dispatch(getContacts(props.packageID, props.scope));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />,
            },
            props.scope,
          ),
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.',
            },
            props.scope,
          ),
        );
      }
    } else {
      // Clear any existing error message
      dispatch(uxClearContainerMessageAction(props.scope));
    }
  });

  // Check if there are no contacts to display
  if (contacts === undefined || isEqual(contacts, {}) || isEmpty(contacts)) {
    return (
      <div className="default-contract">
        <b style={{ color: '#605E5C', letterSpacing: 2 }}>
          THERE ARE NO CUSTOMER CONTACTS TO DISPLAY
        </b>
      </div>
    );
  }

  // Otherwise, display the returned contact data
  else {
    // eslint-disable-next-line array-callback-return
    contacts?.map((x: any) => {
      const ContactsJSON = {
        'Unique ID': x?.uniqueId ?? 'null',
        'Role Name': x?.contactRoleName ?? '',
        Email: x?.contactEmail ?? 'null',
        'Access ID': x?.accessId ?? 'null',
        'AccessID Active': x.isAccessIdActive.toString(),
        Registered: x.isRegistered.toString(),
      };
      contactColumns.push(ContactsJSON);
    });
    return (
      <>
        <ElxTableContainer
          containerProps={{
            headerText: 'SEARCH CUSTOMER CONTACTS',
            fillBackground: false,
            compact: false,
          }}
          tableProps={{
            compact: true,
            columns: getColumns(),
            items: contactColumns,
            selectionMode: SelectionMode.none,
          }}
          searchBoxProps={searchBoxProps}
        />
      </>
    );
  }

  function getColumns(): IElxColumn[] {
    return [
      {
        key: '2',
        name: 'Unique ID',
        fieldName: 'Unique ID',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: '3',
        name: 'Email',
        fieldName: 'Email',
        minWidth: 200,
        maxWidth: 350,
        isResizable: true,
      },
      {
        key: '4',
        name: 'Role',
        fieldName: 'Role Name',
        minWidth: 150,
        maxWidth: 300,
        isResizable: true,
      },
      {
        key: '5',
        name: 'Access ID',
        fieldName: 'Access ID',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
      {
        key: '6',
        name: 'AccessID Active',
        fieldName: 'AccessID Active',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
      {
        key: '7',
        name: 'Registered',
        fieldName: 'Registered',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
    ];
  }
};

export default Contacts;
