import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    ElxScopedContainer,
    useScope,
    uxAddContainerMessageAction,
    uxClearContainerMessageAction,
    uxSetSidebarDefaultCollapsedAction,
} from '@elixir/fx';
import { ResponsiveMode, useResponsiveContext } from '@elixir/styling';

import {
    ContainerNavigationItem,
    ElxSearchBox,
    IElxSidebarItemProps,
    ISearchCriteria,
    SearchBoxSize,
    ElxMessageBarType,
} from '@elixir/components';

import SettingDetails from './settingDetails';
import { getSetting } from '../store/action';
import '../styling/css/style.css';
import { isEmpty } from 'lodash';
import { ISettings, ISettingsModel } from '../store/types/variabletypes';
import { ErrorType, IError } from '../../utility/errorHandling';
import { PermissionErrorMessage } from '../../utility/PermissionErrorMessage';

interface NavigationItem extends ContainerNavigationItem {
	settingList: Array<object>;
}
export const Settings = () => {
	const scope = useScope();
	const dispatch = useDispatch();
	const { responsiveMode } = useResponsiveContext();
	let navigationItems: {
		key: any;
		text: any;
		iconProps: any;
		settingList: Array<ISettings>;
		count: any;
		userId: any;
	}[] = [];

  // Subscribe for data changes
	const error: IError | undefined = useSelector(
		(state: any) => state?.modules?.Features?.error
	);
	const settingList: Array<ISettingsModel> | undefined = useSelector(
		(state: any) => state?.modules?.Settings?.settingList
	);

  // Request all settings
	useEffect(() => {
		dispatch(getSetting(scope));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  // Setup state variables

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [navigationPaneCollapsed, setNavigationPaneCollapsed] = useState(false);
	const [selectedKey, setSelectedKey] = useState('');
	const [searchCriteria, setSearchCriteria] = useState<ISearchCriteria>({
		keyword: '',
		filters: {},
	});

	if (settingList !== undefined && !isEmpty(settingList)) {
		navigationItems = settingList
			?.map((x: ISettingsModel) => {
				const settingJSON = {
					key: x?.distinctServiceName.toUpperCase(),
					text:
						x?.distinctServiceName.toUpperCase() +
						' (' +
						x.settingList.length +
						')',
					settingList: x?.settingList,
					iconProps: { iconName: 'CRMReport' },
					count: x.settingList.length,
					userId: x.userId,
				};
				return settingJSON;
			})
			.filter(navItem => {
				if (!searchCriteria.keyword) {
					return true;
				}
				navItem.settingList = navItem.settingList.filter(setting =>
					setting.Name?.Value?.toLowerCase().includes(
						searchCriteria.keyword.trim().toLowerCase()
					)
				);
				navItem.text = navItem.key + '(' + navItem.settingList.length + ')';
				return navItem.settingList.length > 0;
			});
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const navigationItem = useMemo(() => {
		return navigationItems.sort((a, b) => {
			return b.count - a.count;
		}) as NavigationItem[];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigationPaneCollapsed]);

	// Check for an error
	useEffect(() => {
		if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
			if (error?.errorType === ErrorType.Permission) {
				dispatch(
					uxAddContainerMessageAction(
						{
							type: ElxMessageBarType.error,
							message: (
								<PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />
							)
						},
						scope)
				);
			} else {
				dispatch(
					uxAddContainerMessageAction(
						{
							type: ElxMessageBarType.error,
							message: error?.errorMessage ?? 'An unexpected error occurred.'
						},
						scope)
				);
			}
		} else {
			// Clear any existing error message
			dispatch(uxClearContainerMessageAction(scope));
		}
	});

  // Check if there are no settings
	if (settingList === undefined) {
		return (
			<div className='default-contract'>
				<b style={{ color: '#605E5C', letterSpacing: 2 }}>
					THERE ARE NO RESULTS TO DISPLAY. SELECT A SETTING !{' '}
				</b>
			</div>
		);
	}

	// Otherwise, display setting list
	else {
		return (
			<ElxScopedContainer
				scope={scope}
				headerText='SETTINGS'
				subActions={[
					<ElxSearchBox
						size={SearchBoxSize.Medium}
						onChange={setSearchCriteria}
						placeholder='Search'
						key={1}
					/>
				]}
				navigationProps={{
					items: navigationItems.sort((a, b) => {
						return b.count - a.count;
					}),
					selectedKey: navigationItems.length > 0 ? selectedKey : '',
					onItemSelect: (_, item: IElxSidebarItemProps) => {
						setSelectedKey(item.key);
						dispatch(uxSetSidebarDefaultCollapsedAction(true));
					},
				}}
				styles={{
					subHeader: {
						display: 'none',
					},
					subActions: { display: 'flex' },
					body: {
						...(responsiveMode > ResponsiveMode.Large && {
							padding: 0,
						}),
					},
				}}>
				<SettingDetails
					scope={scope}
					selectedKey={selectedKey}
					settingList={
						navigationItems.find(obj => obj.key === selectedKey)?.settingList ??
						[]
					}
					userId={navigationItems.find(obj => obj.key === selectedKey)?.userId}
				/>
			</ElxScopedContainer>
		);
	}
};
export default Settings;
