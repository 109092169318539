import '../../../styling/css/style.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  IStackTokens,
  mergeStyles,
  Checkbox,
  DatePicker,
  IDatePickerStyles,
} from '@fluentui/react';
import { Stack } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { getAssessment, overrideAssessment } from '../store/action';
import { IAssessmentColumns, IRolesColumns } from '../store/types';
import { ErrorType, IError } from '../../utility/errorHandling';
import { PermissionErrorMessage } from '../../utility/PermissionErrorMessage';
import { IScope, uxAddContainerMessageAction, uxClearContainerMessageAction } from '@elixir/fx';

import { ElxPrimaryButton, ElxMessageBarType } from '@elixir/components';

export const cardContentStackTokens: IStackTokens = {
  childrenGap: 'l1',
};
export const cardContentStackStyles2 = mergeStyles({
  width: 350,
});
const stackTokens = { childrenGap: 10 };

export interface IAssessmentInterface {
  id: string;
  name: string;
  description: string;
  internalName: string;
  type: number;
  expiryDate: Date | null;
}

export const Assessments = (props: { workspaceID: string; scope: IScope }) => {
  const dispatch = useDispatch();
  const datePickerStyles: Partial<IDatePickerStyles> = {
    root: { maxWidth: 300, marginTop: 15 },
  };
  const assessmentColumns: IAssessmentColumns[] = [];
  const claims: any = {};
  const [checkboxStates, setCheckboxStates] = useState<{
    [key: string]: {
      id: string;
      expiryDate: Date;
      assessmentId: string;
      checked: boolean;
    };
  }>({
    1: { id: '', expiryDate: new Date(), assessmentId: '', checked: false },
  });

  // Subscribe for data changes
  const error: IError | undefined = useSelector((state: any) => state?.modules?.Operations?.error);
  const assessments: Array<IAssessmentInterface> = useSelector(
    (state: any) => state?.modules?.Operations?.assessments,
  );

  // Setup event handlers
  function _onChange(
    id: string,
    name: string,
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean,
  ) {
    setCheckboxStates({
      ...checkboxStates,
      [name]: { ...checkboxStates[name], id, checked: isChecked ?? false },
    });
  }

  function _onChangeDate(name: string, expiry: Date | null | undefined) {
    if (expiry !== null) {
      setCheckboxStates({
        ...checkboxStates,
        [name]: { ...checkboxStates[name], expiryDate: expiry ?? new Date() },
      });
    }
  }

  function postAssessmentJSON() {
    const result = [];
    let flag = false;
    for (const i of assessments) {
      if (i.expiryDate !== null) {
        let isChecked = true;

        for (const checkboxState in checkboxStates) {
          if (checkboxState === i.name) {
            isChecked = false;
            break;
          }
        }
        isChecked &&
          result.push({
            AssessmentId: i?.id,
            ExpiryDate: i?.expiryDate,
          });
      }
    }
    for (const checkboxState in checkboxStates) {
      if (checkboxStates[checkboxState].checked) {
        if (
          checkboxStates[checkboxState].expiryDate === null ||
          checkboxStates[checkboxState].expiryDate === undefined
        ) {
          flag = true;
          //alert("Select Date");
          dispatch(
            uxAddContainerMessageAction(
              {
                type: ElxMessageBarType.error,
                message: `Please select the Assessment End Date`,
              },
              props.scope,
            ),
          );
          break;
        } else {
          result.push({
            AssessmentId: checkboxStates[checkboxState].id,
            ExpiryDate: checkboxStates[checkboxState]?.expiryDate?.toISOString(),
          });
        }
      }
    }
    !flag && dispatch(overrideAssessment(props.workspaceID, result, props.scope));
  }

  // Request assessment data
  useEffect(() => {
    dispatch(getAssessment(props.workspaceID, props.scope));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Do something with any returned data

  // Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />,
            },
            props.scope,
          ),
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.',
            },
            props.scope,
          ),
        );
      }
    } else {
      // Clear any existing error message
      dispatch(uxClearContainerMessageAction(props.scope));
    }
  });

  // Check if there is no assessment data to display
  if (assessments === undefined || isEqual(assessments, {}) || isEmpty(assessments)) {
    return (
      <div className="default-contract">
        <b style={{ color: '#605E5C', letterSpacing: 2 }}>THERE ARE NO ASSESSMENTS TO DISPLAY ! </b>
      </div>
    );
  }

  // Otherwise, display assessment data
  else {
    const temp: {
      [key: string]: { id: string; expiryDate: Date; checked: boolean };
    } = { 1: { id: '', expiryDate: new Date(), checked: false } };
    //eslint-disable-next-line array-callback-return
    assessments.map((x: any) => {
      const assessmentsJSON = {
        Name: x.name,
        Expiry: x.expiryDate,
        Id: x.id,
      };
      if (x?.claimsForRole) {
        claims[`${x.roleId}`] = x.claimsForRole;
      }
      assessmentColumns.push(assessmentsJSON);
      temp[assessmentsJSON.Name] = {
        id: assessmentsJSON.Name,
        expiryDate: assessmentsJSON.Expiry,
        checked: assessmentsJSON.Expiry ? true : false,
      };
    });
    return (
      <div className="assessment-container">
        {assessmentColumns.map((column: IRolesColumns, index: number) => {
          return (
            <Stack key={column.Name} tokens={stackTokens}>
              <Checkbox
                key={index}
                label={column.Name}
                name={column.Name}
                checked={checkboxStates[column.Name]?.checked}
                defaultChecked={!column.Expiry ? false : true}
                onChange={(ev, isChecked) => {
                  _onChange(column.Id, column.Name, ev, isChecked);
                }}
              />
              <DatePicker
                id="datepicker"
                styles={datePickerStyles}
                placeholder="SELECT ASSESSMENT END DATE"
                ariaLabel="SELECT ASSESSMENT END DATE"
                value={column.Expiry ? new Date(column.Expiry) : undefined}
                allowTextInput
                style={{ marginBottom: 15 }}
                hidden={column.Expiry ? false : !checkboxStates[column.Name]?.checked}
                onSelectDate={date => {
                  _onChangeDate(column.Name, date);
                }}
                isRequired={true}
              />
            </Stack>
          );
        })}
        <br />
        <ElxPrimaryButton
          text="Save Overrides"
          title="json"
          className="json-button"
          onClick={() => postAssessmentJSON()}
        />
      </div>
    );
  }
};

export default Assessments;
