import {
  ElxScopedContainer,
  IScope,
  useScope,
  uxAddContainerMessageAction,
  uxClearContainerMessageAction,
} from '@elixir/fx';
import {
  ElxPanel,
  ElxCopy,
  ElxIconButton,
  PanelSize,
  ElxPrimaryButton,
  IMessage,
  ElxMessageBarType,
} from '@elixir/components';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorType, IError } from '../../../utility/errorHandling';
import { getContractByAliasDetails } from '../../store/action'
import { PermissionErrorMessage } from '../../../utility/PermissionErrorMessage';
import JSONPretty from 'react-json-pretty';

interface IPanelExampleState {
  show: boolean;
  size: PanelSize;
  message?: IMessage;
  isLoading: boolean;
  fillBackground: boolean;
}

export const ContractSearchByAlias = (props: { scope: IScope }) => {
  const scope = useScope()
  const dispatch = useDispatch()

  const [copyJSON, setCopyJSON] = React.useState<string>('');
  const [panelState, setPanelState] = React.useState<IPanelExampleState>({
    show: false,
    size: PanelSize.medium,
    isLoading: false,
    fillBackground: false,
  });
  const { show, size, isLoading, fillBackground, message } = panelState;

  // Subscribe for data changes
  const error: IError | undefined = useSelector(
    (state: any) => state?.modules?.Contract?.error
  );
  const contractsByAlias: Array<Object> = useSelector((state: any) => state?.modules?.Contract?.contractsByAlias);

  // Setup state variables
  const [csamAlias, setCsamAlias] = useState('')
  const [installationID, setInstallationID] = useState('')

  // Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: (
                <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />
              )
            },
            props.scope)
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.'
            },
            props.scope)
        );
      }
      setPanelState(state => ({
        ...state,
        show: false,
        size,
        fillBackground,
      }));
    } else { // clear any previous message
      dispatch(uxClearContainerMessageAction(props.scope));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // Callback to open Contract Details panel searched by alias
  function openPanel(
    sc: IScope,
    csamAlias: string,
    installationID: string,
    size: PanelSize,
    fillBackground: boolean,
    setPanelState: React.Dispatch<React.SetStateAction<IPanelExampleState>>,
  ): void {
    setPanelState(state => ({
      ...state,
      show: true,
      size,
      fillBackground,
    }));
    dispatch(getContractByAliasDetails(csamAlias, installationID, sc));
  }

  var JSONPrettyMon = require('react-json-pretty/dist/monikai');

  return (
    <div style={{ padding: 20 }}>
      <ElxPanel
          headerText="Contracts JSON"
          isOpen={show}
          size={size}
          fillBackground={fillBackground}
          message={message}
          isLoading={isLoading}
          onDismiss={() => setPanelState({ ...panelState, show: false })}>
          <div className="contract-modal">
            <ElxCopy
              textToCopy={JSON.stringify(contractsByAlias, null, 2)}
              onClick={() => setCopyJSON(copyJSON)}>
              <ElxIconButton text="Copy" iconProps={{ iconName: 'Info' }} />
            </ElxCopy>
            <ElxScopedContainer scope={scope}>
              <JSONPretty
                data={contractsByAlias}
                theme={JSONPrettyMon}
                style={{ padding: 10 }} />
            </ElxScopedContainer>
          </div>
      </ElxPanel>

      <ElxScopedContainer scope={scope}>
        <div style={{ margin: 20 }}>
          <div>
            <input
              type="text"
              className="csam-alias-input-style"
              placeholder="Enter CSAM Alias"
              value={csamAlias}
              onChange={(event) => setCsamAlias(event.target.value)}
            />
          </div>
          <div>
            <input
              type="text"
              className="csam-alias-input-style"
              placeholder="Enter Installation ID"
              value={installationID}
              onChange={(event) => setInstallationID(event.target.value)}
            />
          </div>
          <div>
            <ElxPrimaryButton
              text="Show JSON"
              className="json-button"
              onClick={() =>
                openPanel(scope, csamAlias, installationID, PanelSize.large, false, setPanelState)
              }
              disabled={!csamAlias}
            />
          </div>
        </div>
      </ElxScopedContainer>
    </div>
  );
}

export default ContractSearchByAlias