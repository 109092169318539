import React, { useEffect } from 'react'
import { IStackTokens, mergeStyles } from '@fluentui/react'
import '../../../styling/css/style.css'
import { useSelector, useDispatch } from 'react-redux'
import { getServices } from '../../../store/action'
import { IScope, uxAddContainerMessageAction, uxClearContainerMessageAction } from '@elixir/fx';
import {
  ElxTableContainer,
  IElxColumn,
  IElxContainerProps,
  IElxSearchBoxProps,
  ElxMessageBarType,
} from '@elixir/components';
import { SelectionMode } from '@fluentui/react';
import { ErrorType, IError } from '../../../../utility/errorHandling'
import { PermissionErrorMessage } from '../../../../utility/PermissionErrorMessage';
import { isEmpty, isEqual } from 'lodash'

export const cardContentStackStyles = mergeStyles({
  width: 200,
})
export const cardContentStackTokens: IStackTokens = {
  childrenGap: 'l2',
}

export const Services = (props: {
  packageID: string
  scope: IScope
  searchProps?: IElxSearchBoxProps
  containerProps?: IElxContainerProps
}) => {
  const searchBoxProps = {
    ...props.searchProps,
  }

  const serviceColumns: {
    'Component Code': any
    'Service/SKU': any
    'Component Name': any
    'Component Type': string
    Purchases: any
    'Start Date': any
    'End Date': any
    'Available Units': any
    'Purchased Units': any
    'Support Unit Type': any
  }[] = []

  const dispatch = useDispatch()

  // Subscribe for data changes
  const error: IError | undefined = useSelector(
    (state: any) => state?.modules?.Contract?.error
  );
  const services: Array<Object> = useSelector(
    (state: any) => state?.modules?.Contract?.services,
  );

  // Get services for current Contract
  useEffect(() => {
    dispatch(getServices(props.packageID, props.scope))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: (
                <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />
              )
            },
            props.scope)
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.'
            },
            props.scope)
        );
      }
    } else {
      // Clear any existing error message
      dispatch(uxClearContainerMessageAction(props.scope));
    }
  });

  // Check if there are no services to be displayed
  if (services === undefined || isEqual(services, {}) || isEmpty(services)) {
    return (
      <div className="default-contract">
        <b style={{ color: '#605E5C', letterSpacing: 2 }}>
          THERE ARE NO SERVICES TO DISPLAY
        </b>
      </div>
    )
  }

  // Otherwise, display the returned service data
  else {
    // eslint-disable-next-line array-callback-return
    services?.map((x: any) => {
      const startDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      }).format(new Date(x.startDate))

      const endDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      }).format(new Date(x.endDate))

      const servicesJSON = {
        'Component Code': x?.sku?.consumptionComponentCode,
        'Service/SKU': x?.sku?.id,
        'Component Name': x?.sku?.consumptionComponentName,
        'Component Type': '',
        Purchases: x?.supportUnit?.purchasedUnits,
        'Start Date': startDate,
        'End Date': endDate,
        'Available Units': x?.supportUnit?.availableUnits,
        'Purchased Units': x?.supportUnit?.purchasedUnits,
        'Support Unit Type': x?.supportUnit?.type,
      }
      serviceColumns.push(servicesJSON)
    })

    return (
      <>
        <ElxTableContainer
          containerProps={{
            headerText: 'SEARCH SERVICES',
            fillBackground: false,
            compact: false,
          }}
          tableProps={{
            compact: true,
            columns: getColumns(),
            items: serviceColumns,
            selectionMode: SelectionMode.none,
          }}
          searchBoxProps={searchBoxProps}
        />
      </>
    )
  }

  function getColumns(): IElxColumn[] {
    return [
      {
        key: '2',
        name: 'Component Code',
        fieldName: 'Component Code',
        minWidth: 120,
        maxWidth: 150,
        isResizable: true,
      },
      {
        key: '3',
        name: 'Service/SKU',
        fieldName: 'Service/SKU',
        minWidth: 150,
        maxWidth: 500,
        isResizable: true,
      },
      {
        key: '4',
        name: 'Component Name',
        fieldName: 'Component Name',
        minWidth: 300,
        maxWidth: 500,
        isResizable: true,
      },
      {
        key: '5',
        name: 'Start Date',
        fieldName: 'Start Date',
        minWidth: 90,
        maxWidth: 90,
        isResizable: true,
      },
      {
        key: '6',
        name: 'End Date',
        fieldName: 'End Date',
        minWidth: 90,
        maxWidth: 90,
        isResizable: true,
      },
      {
        key: '7',
        name: 'Support Unit',
        fieldName: 'Support Unit Type',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
      {
        key: '8',
        name: 'Purchased Units',
        fieldName: 'Purchased Units',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
      {
        key: '9',
        name: 'Available Units',
        fieldName: 'Available Units',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
      {
        key: '10',
        name: 'Purchases',
        fieldName: 'Purchases',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
    ]
  }
}

export default Services
