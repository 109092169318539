import '../../../styling/css/style.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStackTokens, mergeStyles } from '@fluentui/react';
import { isEmpty, isEqual } from 'lodash';
import { useEffect } from 'react';
import { getUserEntitlements } from '../store/action';
import {
    IScope,
    ElxScopedContainer,
    uxAddContainerMessageAction,
    uxClearContainerMessageAction,
} from '@elixir/fx';
import {
    ElxTableContainer,
    IElxColumn,
    IElxSearchBoxProps,
    IElxContainerProps,
    ElxCopy,
    ElxIconButton,
    ElxMessageBarType,
} from '@elixir/components';
import { SelectionMode } from '@fluentui/react';
import Collapsible from 'react-collapsible';
import {
	getCurrentThemeFromStorage,
	darkTheme,
	lightTheme,
} from '../../../app';
import { ICommercialColumns } from '../store/types';
import { ErrorType, IError } from '../../utility/errorHandling';
import { PermissionErrorMessage } from '../../utility/PermissionErrorMessage';
import JSONPretty from 'react-json-pretty';

export const cardContentStackStyles = mergeStyles({
	width: 250,
});
export const cardContentStackTokens: IStackTokens = {
	childrenGap: 'l1',
};

export const CommercialEntitlements = (props: {
	UserID: string;
	scope: IScope;
	searchProps?: IElxSearchBoxProps;
	containerProps?: IElxContainerProps;
}) => {
	const dispatch = useDispatch();
	const theme = getCurrentThemeFromStorage(localStorage);
	var JSONPrettyMon = require('react-json-pretty/dist/monikai');
	const PremierColumns: ICommercialColumns[] = [];
	const searchBoxProps = {
		...props.searchProps,
	};

	// Subscribe for data changes
  const error: IError | undefined = useSelector(
		(state: any) => state?.modules?.Operations?.error
  );
	const commercial: Array<Object> = useSelector(
		(state: any) => state?.modules?.Operations?.entitlement
	);

  // Setup state variables
	const [copyJSON, setCopyJSON] = React.useState<string>('');

	// Request entitlement data
	useEffect(() => {
		dispatch(getUserEntitlements(props.UserID, 'true', props.scope));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  // Do something with any returned data

	// Check for an error
	useEffect(() => {
		if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
			if (error?.errorType === ErrorType.Permission) {
				dispatch(
					uxAddContainerMessageAction(
						{
							type: ElxMessageBarType.error,
							message: (
								<PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />
							)
						},
						props.scope)
				);
			} else {
				dispatch(
					uxAddContainerMessageAction(
						{
							type: ElxMessageBarType.error,
							message: error?.errorMessage ?? 'An unexpected error occurred.'
						},
						props.scope)
				);
			}
		} else {
			// Clear any existing error message
			dispatch(uxClearContainerMessageAction(props.scope));
		}
	});

  // Check if there is no entitlement data to display
	if (commercial === undefined || isEqual(commercial, {}) ||	isEmpty(commercial)) {
		return (
			<div className='default-contract'>
				<b style={{ color: '#605E5C' }}>THERE ARE NO COMMERCIAL ENTITLEMENTS</b>
			</div>
		);
	}
	
	// Otherwise, display entitlement data
	else {
		if (commercial?.length > 0) {
			// eslint-disable-next-line array-callback-return
			commercial?.map((x: any) => {
				const premierJSON = {
					EntitlementFriendlyName: x?.entitlementFriendlyName ?? 'N/A',
					'Schedule Id': x?.scheduleId ?? 'N/A',
					'Line Item Id': x?.lineItemId ?? 'N/A',
					'Component Code': x?.componentCode ?? 'N/A',
					'Access ID': x?.accessId ?? 'N/A',
					'Service Id': x?.serviceId ?? 'N/A',
				};
				PremierColumns.push(premierJSON);
			});
		}

		return (
			<>
				<ElxScopedContainer scope={props.scope}>
					<ElxTableContainer
						containerProps={{
							headerText: 'SEARCH COMMERCIAL ENTITLEMENTS',
							fillBackground: false,
							compact: false,
						}}
						tableProps={{
							compact: true,
							columns: getColumns(),
							items: PremierColumns,
							selectionMode: SelectionMode.none,
						}}
						searchBoxProps={searchBoxProps}
					/>
				</ElxScopedContainer>
				<div className='entitlements-collapse'>
					<Collapsible
						trigger='SHOW COMMERCIAL ENTITLEMENTS JSON'
						className='collapsibleStyles'
						role='required'
						transitionTime={600}
						triggerStyle={{
							fontWeight: 600,
							color:
								theme === 'darkTheme'
									? darkTheme.semanticColors.link
									: lightTheme.semanticColors.link,
							cursor: 'Pointer',
						}}>
						<div className='entitlements-json-css'>
							<ElxCopy
								textToCopy={JSON.stringify(commercial, null, 2)}
								onClick={() => setCopyJSON(copyJSON)}>
								<ElxIconButton text='Copy' iconProps={{ iconName: 'Info' }} />
							</ElxCopy>
							<JSONPretty
								data={commercial}
								theme={JSONPrettyMon}
								style={{ padding: 10 }}></JSONPretty>
						</div>
					</Collapsible>
				</div>
			</>
			// <ElxTable
			// 	items={PremierColumns}
			// 	columns={getColumns()}
			// 	selectionMode={SelectionMode.none}
			// />
		);
	}

	function getColumns(): IElxColumn[] {
		return [
			{
				key: '2',
				name: 'Entitlement Friendly Name',
				fieldName: 'EntitlementFriendlyName',
				minWidth: 300,
				maxWidth: 500,
				isResizable: false,
			},
			{
				key: '3',
				name: 'ScheduleId',
				fieldName: 'Schedule Id',
				minWidth: 80,
				maxWidth: 80,
				isResizable: true,
			},
			{
				key: '4',
				name: 'LineItemId',
				fieldName: 'Line Item Id',
				minWidth: 80,
				maxWidth: 80,
				isResizable: true,
			},
			{
				key: '5',
				name: 'ComponentCode',
				fieldName: 'Component Code',
				minWidth: 100,
				maxWidth: 100,
				isResizable: true,
			},
			{
				key: '6',
				name: 'AccessID',
				fieldName: 'Access ID',
				minWidth: 70,
				maxWidth: 70,
				isResizable: true,
			},
			{
				key: '7',
				name: 'ServiceId',
				fieldName: 'Service Id',
				minWidth: 70,
				maxWidth: 70,
				isResizable: true,
			},
		];
	}
};

export default CommercialEntitlements;
