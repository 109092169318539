import { ElxSecondaryButton } from '@elixir/components';
import { makeStyles, Theme } from '@fluentui/react';
import { getElxTheme } from '@elixir/styling';
import { logout } from '@elixir/auth';
import { getLogger } from '@elixir/telemetry';
import { getFrameworkInstance, Http, IScope, uxSetContainerLoadingAction } from '@elixir/fx';
import { getConfig, IConfig } from './OpsConfig';

export const useUnauthorizedPageStyles = makeStyles((theme: Theme) => ({
  root: [
    'elx-unauthorized',
    {
      display: 'flex',
      height: '100vh',
      padding: 0,
      margin: 0,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  ],
}));

const ElxTheme = getElxTheme();
export const logoutButtonStyles = {
  root: [
    'elx-logout',
    {
      margin: ElxTheme.spacing.space1,
    },
  ],
};

export const USER_NOT_AUTHORIZED_MESSAGE = 'User is not authorized to access this site.';

export const NoAccess = () => {
  const classnames = useUnauthorizedPageStyles();
  const logoutFn = () => {
    getLogger().trace(USER_NOT_AUTHORIZED_MESSAGE);
    logout();
  };
  return (
    <div className={classnames.root}>
      {USER_NOT_AUTHORIZED_MESSAGE}
      <ElxSecondaryButton styles={logoutButtonStyles} text="Logout" onClick={logoutFn} />
    </div>
  );
};
export default NoAccess;

export async function checkUserAccess(apiBaseURL: string): Promise<boolean> {
  try {
    await Http.get<IConfig>({}, apiBaseURL + '/auth/ValidateUser');
    return true;
  } catch (e) {
    return false;
  }
}

export const userAccess = (
  setIsUserAccess: React.Dispatch<React.SetStateAction<boolean>>,
  scope: IScope,
) => {
  const store = getFrameworkInstance()?.store;
  getConfig().then(async (config: IConfig) => {
    const isAccess = await checkUserAccess(config.apiBaseURL);
    store?.dispatch(uxSetContainerLoadingAction(false, '', scope));
    if (!isAccess) {
      setIsUserAccess(false);
    }
  });
};
