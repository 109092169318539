import '../../../styling/css/style.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IIncidentModel from '../store/types/variabletypes';
import { IStackTokens, IStackItemStyles, mergeStyles } from '@fluentui/react';
import { Toggle } from '@fluentui/react/lib/Toggle';
import EmptyData from '../styling/images/EmptyData.svg';
import { isEmpty, isEqual } from 'lodash';
import { DocumentCard, DocumentCardTitle } from '@fluentui/react/lib/DocumentCard';
import { Stack } from '@fluentui/react';
import JSONPretty from 'react-json-pretty';
import Collapsible from 'react-collapsible';
import { useLocation } from 'react-router';
import {
  ElxScopedContainer,
  IScope,
  useScope,
  uxAddContainerMessageAction,
  uxClearContainerMessageAction,
} from '@elixir/fx';

import {
  ElxCopy,
  ElxIconButton,
  ElxPanel,
  ElxSecondaryButton,
  IMessage,
  isNullOrWhitespace,
  ElxMessageBarType,
  PanelSize,
} from '@elixir/components';

import {
  addAccessToWorkspace,
  getSearchResults,
  removeAccessFromWorkspaces,
} from '../store/action';
import { Link } from '@fluentui/react';
import Assessments from './Assessments';
import { ResultStatus, ErrorType, IError } from '../../utility/errorHandling';
import { IWorkspaceColumns } from '../store/types';
import { PermissionErrorMessage } from '../../utility/PermissionErrorMessage';
import { getCurrentThemeFromStorage, darkTheme, lightTheme } from '../../../app';
import Deleted_LightTheme from '../styling/images/Deleted_LightTheme.svg';
import Deleted_DarkTheme from '../styling/images/Deleted_DarkTheme.svg';

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: 5,
    cursor: 'Pointer',
    flex: 1,
  },
};
export const cardContentStackStyles2 = mergeStyles({
  width: 250,
});
export const packageStyles = mergeStyles({
  marginTop: 10,
});
export const cardContentStackTokens: IStackTokens = {};
export const cardContentStackStyles = mergeStyles({
  width: 400,
});

interface IPanelExampleState {
  show: boolean;
  size: PanelSize;
  message?: IMessage;
  isLoading: boolean;
  fillBackground: boolean;
}

export const Workspace = (props: { scope: IScope; OpsType: string; servicesHubUrl: string }) => {
  const scope = useScope();
  const theme = getCurrentThemeFromStorage(localStorage);
  const workspaceColumns: IWorkspaceColumns[] = [];
  const workspaceColumns2: { [key: string]: Array<any> } = {};
  const workspaceColumns3: { [key: string]: IWorkspaceColumns } = {};
  const workspaceColumns4: { [key: string]: boolean } = {};
  const workspaceList: { [key: string]: IWorkspaceColumns } = {};
  const [workspaceID, setWorkspaceID] = useState('');
  const [copyJSON, setCopyJSON] = React.useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const Id = queryParams.get('id') ?? '';
  const dispatch = useDispatch();

  // Subscribe for data changes
  const error: IError | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.error
  );
  const workspace: IIncidentModel<Object> | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.allresults,
  );
  const accessibleWorkspaceIdList: Array<string> | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.accessibleWorkspaceIdList,
  );
  const addAccessToWorkspaceStatus: ResultStatus | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.addAccessToWorkspaceStatus
  );
  const removeAccessFromWorkspaceStatus: ResultStatus | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.removeAccessFromWorkspaceStatus
  );
  const addRemoveAccessId: string | undefined = useSelector(
    (state: any) => state?.modules?.Operations?.id
  );

  // Setup state variables
  const [panelData, setPanelData] = useState({});
  const [panelState, setPanelState] = React.useState<IPanelExampleState>({
    show: false,
    size: PanelSize.small,
    isLoading: false,
    fillBackground: false,
  });
  
  const [panelState2, setPanelState2] = React.useState<IPanelExampleState>({
    show: false,
    size: PanelSize.small,
    isLoading: false,
    fillBackground: false,
  });

  // Setup event handlers
  function openPanel(
    data: any,
    size: PanelSize,
    fillBackground: boolean,
    setPanelState: React.Dispatch<React.SetStateAction<IPanelExampleState>>,
  ): void {
    setPanelData(data);
    setPanelState(state => ({
      ...state,
      show: true,
      size,
      fillBackground,
    }));
  }

  function openPanel2(
    id: string,
    data: any,
    size: PanelSize,
    fillBackground: boolean,
    setPanelState2: React.Dispatch<React.SetStateAction<IPanelExampleState>>,
  ): void {
    setWorkspaceID(id);
    setPanelData(data);
    setPanelState2(state => ({
      ...state,
      show: true,
      size,
      fillBackground,
    }));
  }

  function _onChange(ev: React.MouseEvent<HTMLElement>, checked: boolean, workspaceID: string) {
    if (checked) {
      dispatch(addAccessToWorkspace(workspaceID, props.scope));
    } else {
      dispatch(removeAccessFromWorkspaces(workspaceID, props.scope));
    }
    workspaceColumns4[workspaceID] = checked;
  }

  // Search for requested workspaces
  useEffect(() => {
    if (!isNullOrWhitespace(Id)) {
      dispatch(getSearchResults(Id, props.OpsType, props.scope));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Do something with any returned data

  // Check for an error
  useEffect(() => {
    if (!isEmpty(error) && error?.errorType !== ErrorType.None) {
      if (error?.errorType === ErrorType.Permission) {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: (
                <PermissionErrorMessage missingRole={`${error?.data ?? '(unknown)'}`} />
              )
            },
            props.scope)
        );
      } else {
        dispatch(
          uxAddContainerMessageAction(
            {
              type: ElxMessageBarType.error,
              message: error?.errorMessage ?? 'An unexpected error occurred.'
            },
            props.scope)
        );
      }
    }

    // Display message if one of the add/remove access actions was successful
    else if (addAccessToWorkspaceStatus === ResultStatus.Success) {
      dispatch(
        uxAddContainerMessageAction(
          {
            type: ElxMessageBarType.success,
            message: `You have been granted access to Workspace ${addRemoveAccessId}.`,
          },
          props.scope
        )
      );
    }
    else if (removeAccessFromWorkspaceStatus === ResultStatus.Success) {
      dispatch(
        uxAddContainerMessageAction(
          {
            type: ElxMessageBarType.success,
            message: `Your access to Workspace ${addRemoveAccessId} has been revoked.`,
          },
          props.scope
        )
      );
    }

    // Otherwise, clear any previous message
    else {
      dispatch(uxClearContainerMessageAction(props.scope));
    }
  });

  // Check if there is no workspace data to display
  if (workspace === undefined || isEqual(workspace, {}) || isEmpty(workspace.results) ||
    !workspace.results.some((r: any) => r.documentType === props.OpsType.toLowerCase())) {
    return (
      <div className="default-contract">
        <b style={{ color: '#605E5C', letterSpacing: 2 }}>
          THERE ARE NO RESULTS TO DISPLAY. START SEARCHING !{' '}
        </b>
      </div>
    );
  } 
  
  // Otherwise, display workspace data
  else {
    if (workspace?.results) {
      // eslint-disable-next-line array-callback-return
      workspace.results?.map((x: any) => {
        const LastUpdated = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        }).format(new Date(x.item.LastUpdated.Date));

        let CreatedDate = 'UNK';
        if (x?.item.Created?.Date != null) {
          CreatedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          }).format(new Date(x.item.Created.Date));
        }

        // const DeletedDate = new Intl.DateTimeFormat('en-US', {
        // 	year: 'numeric',
        // 	month: 'short',
        // 	day: '2-digit',
        // }).format(new Date(x.item.Deleted));

        const workspaceJSON = {
          WorkspaceID: x?.item?.Id ?? 'N/A',
          'COMPANY NAME': x?.item?.CompanyName ?? 'N/A',
          'WORKSPACE NAME': x?.item?.WorkspaceName ?? 'N/A',
          CREATED: CreatedDate ?? 'N/A',
          'LAST UPDATED': LastUpdated ?? 'N/A',
          DELETED: x.item.Deleted?.Date ?? 'N/A',
          TPID: x?.item?.TPID ?? 'N/A',
          LEVEL: x?.item?.Level ?? 'N/A',
        };

        const showWorkspace = {
          Workspace: x?.item ?? 'N/A',
        };
        const accessibleWorkspaceIdList = {
          Workspace: x?.item.Id ?? 'N/A',
        };
        const Entitlements: Array<any> = [];
        if (x?.item?.EntitlementMappings) {
          // eslint-disable-next-line array-callback-return
          x?.item?.EntitlementMappings?.map((Entitle: any, index: number) => {
            if (x?.item?.EntitlementMappings.length > 0) Entitlements.push(Entitle);
            else Entitlements.push('N/A');
          });
        }

        workspaceColumns2[x.item.Id] = Entitlements;
        workspaceColumns3[x.item.Id] = showWorkspace;
        workspaceColumns4[x.item.Id] = x?.hasAccess;
        workspaceList[x.item.Id] = accessibleWorkspaceIdList;
        workspaceColumns.push(workspaceJSON);
      });
    }

    var JSONPrettyMon = require('react-json-pretty/dist/monikai');
    return (
      <div style={{ padding: 20 }}>
        <ElxPanel
          headerText="WORKSPACE JSON"
          isOpen={panelState.show}
          size={panelState.size}
          fillBackground={panelState.fillBackground}
          message={panelState.message}
          isLoading={panelState.isLoading}
          onDismiss={() => setPanelState({ ...panelState, show: false })}>
          <div className="contract-modal">
            <ElxCopy
              textToCopy={JSON.stringify(panelData, null, 2)}
              onClick={() => setCopyJSON(copyJSON)}>
              <ElxIconButton text="Copy" iconProps={{ iconName: 'Info' }} />
            </ElxCopy>
            <JSONPretty data={panelData} theme={JSONPrettyMon} style={{ padding: 10 }}></JSONPretty>
          </div>
        </ElxPanel>
        <ElxPanel
          headerText="ASSESSMENTS OVERRIDE"
          onRenderSubHeader={() => (
            <h2>
              Refer to this&nbsp;
              <Link
                href={`https://dev.azure.com/sirona/PremierHub/_wiki/wikis/PremierHub.wiki/2557/When-to-and-When-NOT-to-override-an-Assessment-`}
                target="_blank"
                underline
                style={{
                  display: 'contents',
                  //ontSize: 15,
                  fontWeight: 600,
                  textDecoration: 'none',
                }}>
                Wiki
              </Link>
              &nbsp;for help
            </h2>
          )}
          isOpen={panelState2.show}
          size={panelState2.size}
          fillBackground={panelState2.fillBackground}
          message={panelState2.message}
          isLoading={panelState2.isLoading}
          onDismiss={() => setPanelState2({ ...panelState2, show: false })}>
          <ElxScopedContainer scope={scope}>
            <Assessments workspaceID={workspaceID} scope={scope} />
          </ElxScopedContainer>
          {/*<Assessments workspaceID={workspaceID} scope={scope} />*/}
        </ElxPanel>

        {workspaceColumns.map((column: IWorkspaceColumns, index: number) => {
          return (
            <div key={`${column['WorkspaceID']}${index}`}>
              <DocumentCard className="sub-card-styles-packages">
                <Stack horizontal>
                  <Stack.Item align="start" styles={stackItemStyles}>
                    <ElxScopedContainer>
                      <DocumentCardTitle title="WORKSPACE" className="user-title" shouldTruncate />
                      {column['DELETED'] !== 'N/A' ? (
                        <>
                          <img
                            src={theme === 'darkTheme' ? Deleted_DarkTheme : Deleted_LightTheme}
                            className="deleted-workspace-img"
                            alt=""
                          />
                        </>
                      ) : (
                        <Toggle
                          inlineLabel
                          className="access-toggle"
                          onText="Access"
                          offText="No Access"
                          checked={
                            workspaceColumns4[column.WorkspaceID] ||
                            accessibleWorkspaceIdList?.includes(column.WorkspaceID)
                          }
                          onChange={(ev, checked?: boolean) =>
                            _onChange(ev, checked ?? false, column.WorkspaceID)
                          }
                        />
                      )}
                    </ElxScopedContainer>
                  </Stack.Item>
                  <Stack.Item align="end">
                    <ElxSecondaryButton
                      text="Assessments"
                      title="json"
                      className="json-button"
                      tabIndex={-1}
                      ariaDescription="Assessments Button"
                      onClick={() =>
                        openPanel2(
                          column['WorkspaceID'],
                          workspaceColumns3[column['WorkspaceID']],
                          PanelSize.medium,
                          false,
                          setPanelState2,
                        )
                      }
                      style={{ marginRight: 10 }}
                    />
                  </Stack.Item>
                  <Stack.Item align="end">
                    <ElxSecondaryButton
                      text="SHOW WORKSPACE JSON"
                      title="json"
                      tabIndex={-1}
                      ariaDescription="Show Workspace JSON Button"
                      className="json-button"
                      onClick={() =>
                        openPanel(
                          workspaceColumns3[column['WorkspaceID']],
                          PanelSize.large,
                          false,
                          setPanelState,
                        )
                      }
                    />
                  </Stack.Item>
                </Stack>
                <Stack>
                  <Link
                    href={`${props.servicesHubUrl}/databoard/${column.WorkspaceID}`}
                    target="_blank"
                    underline
                    tabIndex={-1}
                    disabled={
                      !workspaceColumns4[column.WorkspaceID] && !accessibleWorkspaceIdList?.includes(column.WorkspaceID)
                    }
                    style={{
                      fontSize: 15,
                      fontWeight: 600,
                      textDecoration: 'none',
                    }}>
                    {column.WorkspaceID}
                  </Link>

                  <br />
                  <Stack>
                    {/* <DocumentCardTitle
											className='customer-contract-title'
											title='Workspace Details'
											shouldTruncate
										/> */}
                    <Stack horizontal wrap>
                      {Object.entries(column).map(([key, value], index) => {
                        return key !== 'WorkspaceID' ? (
                          <Stack
                            key={`${value}${index}`}
                            horizontal
                            tokens={cardContentStackTokens}
                            wrap>
                            <Stack className={cardContentStackStyles2}>
                              <Stack.Item>
                                <b>{key}</b>: {value}
                              </Stack.Item>
                            </Stack>
                          </Stack>
                        ) : (
                          <div key={`${value}${index}`}></div>
                        );
                      })}
                    </Stack>
                  </Stack>
                  <div style={{ marginTop: 15, marginBottom: 0 }}>
                    <Collapsible
                      trigger="ENTITLEMENTS MAPPING"
                      transitionTime={600}
                      role="required"
                      triggerStyle={{
                        fontWeight: 750,
                        color:
                          theme === 'darkTheme'
                            ? darkTheme.semanticColors.link
                            : lightTheme.semanticColors.link,
                        cursor: 'Pointer',
                        width: 'auto',
                      }}>
                      <Stack>
                        {Object.keys(workspaceColumns2[column['WorkspaceID']]).length > 0 ? (
                          <div>
                            <Stack horizontal>
                              {workspaceColumns2[column['WorkspaceID']].map((value, index) => {
                                const link = `../contract/BraavosSearch?id=${value.ContractId}`;
                                return (
                                  <div key={`${column['WorkspaceID']}${value.ContractId}${index}`}>
                                    <DocumentCard className="entitlement-cards">
                                      <Stack className={cardContentStackStyles}>
                                        <Stack.Item>
                                          <b>CONTRACT ID:</b>&nbsp;
                                          <a
                                            href={link}
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{
                                              color:
                                                theme === 'darkTheme'
                                                  ? darkTheme.semanticColors.link
                                                  : lightTheme.semanticColors.link,
                                              fontSize: 14,
                                              fontWeight: 600,
                                            }}>
                                            {value.ContractId}
                                          </a>
                                        </Stack.Item>
                                        <Stack.Item>
                                          <b>SCHEDULE ID:</b> {value.ScheduleId}
                                        </Stack.Item>
                                        <Stack.Item>
                                          <b>INSTALLATION SITE ID:</b> {value.InstallationSiteId}
                                        </Stack.Item>
                                      </Stack>
                                    </DocumentCard>
                                  </div>
                                );
                              })}
                            </Stack>
                            <br />
                          </div>
                        ) : (
                          <>
                            <div className="default-contract">
                              <img src={EmptyData} alt="BigCo Inc. logo" />
                              There are no results to display, Start Searching!
                            </div>
                          </>
                        )}
                      </Stack>
                    </Collapsible>
                  </div>
                </Stack>
              </DocumentCard>
            </div>
          );
        })}
      </div>
    );
  }
};

export default Workspace;
