/* eslint-disable react-hooks/rules-of-hooks */
import { Http, containerManagedAction, IScopedPayload } from '@elixir/fx';
import { Logger } from '@elixir/telemetry';
import {
  GET_SCRIPTS_REQUESTED,
  RUN_SCRIPT_REQUESTED,
  CANCEL_SCRIPT_REQUESTED,
  GET_TRACES_REQUESTED,
  IGetScriptsPayload,
  IRunScriptPayload,
  ICancelScriptPayload,
  IGetTracesPayload,
} from './types';
import { SagaIterator } from '@redux-saga/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, put } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { getConfig } from '../../../OpsConfig';
import { getApiErrorAction, ErrorType, IError } from '../../utility/errorHandling';

// NOT USED
//export const NotAvailableMessage: IMessage = {
//	message:
//		'Common Solutions editing is temporarily unavailable.  Please try again in several minutes.',
//	type: ElxMessageBarType.blocked,
//	isMultiline: false,
//};

function* getScripts(action: PayloadAction<IScopedPayload & IGetScriptsPayload>): SagaIterator {
  yield put({ type: 'GET_SCRIPTS_SUCCESS', script: {} });
  try {
    const config = yield call(getConfig);
    let scriptPath;
    scriptPath = `${config.apiBaseURL}/scriptrunner/GetScripts`;
    const scripts: any = yield call(Http.get, action, scriptPath);
    yield put({
      type: 'GET_SCRIPTS_SUCCESS',
      scriptList: scripts.data,
    });
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_SCRIPTS_FAILURE'));
    Logger.error(err);
  } finally {
  }
}
function* runScript(action: PayloadAction<IScopedPayload & IRunScriptPayload>): SagaIterator {
  //yield put(uxClearContainerMessageAction(action.payload.scope));
  const model = {
    scriptName: action.payload.scriptName,
    scriptJson: action.payload.scriptJson,
  };
  try {
    const config = yield call(getConfig);
    let scriptPath = `${config.apiBaseURL}/scriptrunner/RunScript`;
    const script = yield call(Http.post, action, scriptPath, model);
    if (!isEmpty(script.data)) {
      yield put({
        type: 'RUN_SCRIPT_SUCCESS',
        scriptSuccessID: script.data,
      });
    } else {
      yield put({
        type: 'RUN_SCRIPT_FAILURE',
        error: {
          errorType: ErrorType.Other,
          errorMessage: 'Script failed unexpectedly.',
        } as IError,
      });
    }
  } catch (err) {
    yield put(
      getApiErrorAction(
        err,
        'RUN_SCRIPT_FAILURE',
        new Map([[0, 'An unexpected error occurred while attempting to run the script.']]),
      ),
    );
    Logger.error(err);
  } finally {
  }
}
function* cancelScript(action: ICancelScriptPayload): SagaIterator {
  const scriptName = action.scriptName;
  try {
    const config = yield call(getConfig);
    let cancelScriptPath = `${config.apiBaseURL}/scriptrunner/CancelScript?scriptName=${scriptName}`;
    const cancelScript = yield call(Http.post, action, cancelScriptPath);
    if (cancelScript.status === 200) {
      yield put({
        type: 'CANCEL_SCRIPT_SUCCESS',
      });
    } else {
      yield put({
        type: 'CANCEL_SCRIPT_FAILURE',
        error: {
          errorType: ErrorType.Other,
          errorMessage: `Attempted to cancel the script but received a ${cancelScript.status} result`,
        } as IError,
      });
    }
  } catch (err) {
    yield put(
      getApiErrorAction(
        err,
        'CANCEL_SCRIPT_FAILURE',
        new Map([[0, 'An unexpected error occurred while attempting to run the script.']]),
      ),
    );
    Logger.error(err);
  } finally {
  }
}

function* getTraces(action: PayloadAction<IScopedPayload & IGetTracesPayload>): SagaIterator {
  const scriptName = action.payload.scriptName;
  var startDate = action.payload.startDate;
  var endDate = action.payload.endDate;
  var strToDate = new Date(startDate).toISOString();
  var strToEnd = new Date(endDate).toISOString();
  try {
    const config = yield call(getConfig);
    let getTracesPath = `${config.apiBaseURL}/scriptrunner/GetTraces?scriptName=${scriptName}&from=${strToDate}&to=${strToEnd}`;
    const traces = yield call(Http.get, action, getTracesPath);
    yield put({
      type: 'GET_TRACES_SUCCESS',
      traces: traces.data,
    });
  } catch (err) {
    yield put(getApiErrorAction(err, 'GET_TRACES_FAILURE'));
    Logger.error(err);
  } finally {
  }
}

export function* ScriptSagas(): Generator {
  yield takeLatest(GET_SCRIPTS_REQUESTED, containerManagedAction(getScripts));
  yield takeLatest(RUN_SCRIPT_REQUESTED, runScript);
  yield takeLatest(CANCEL_SCRIPT_REQUESTED, cancelScript);
  yield takeLatest(GET_TRACES_REQUESTED, containerManagedAction(getTraces));
}
export default ScriptSagas;
