import { ElxScopedContainer, IScope } from '@elixir/fx';
import { ElxTextField, ElxSecondaryButton, ElxCopy, ElxIconButton } from '@elixir/components';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { cancelScript, runScript } from '../store/action';
import '../styling/css/style.css';

export const RunScripts = (props: {
	changedValue: string;
	changedScriptValue: (newscript: string) => void;
	defaultValue: string;
	panelName: string;
	scope: IScope;
}) => {
	const dispatch = useDispatch();

	const [disableCancel, setDisableCancel] = useState<boolean>(true);
	const [disableRun, setDisableRun] = useState<boolean>(true);
	const [copyJSON, setCopyJSON] = React.useState<string>('');

	function onRunScript(sc: IScope, scriptName: string) {
		var inputValue = (document.getElementById('scriptId') as HTMLInputElement)?.value;
		dispatch(runScript(sc, scriptName, inputValue));
		setDisableCancel(false);
	}

	function onCancelScript(sc: IScope, scriptName: string) {
		dispatch(cancelScript(scriptName, sc));
		setDisableCancel(true);
	}

	return (
		<ElxScopedContainer scope={props.scope}>
			<ElxCopy
				textToCopy={props.changedValue}
				onClick={() => setCopyJSON(copyJSON)}>
				<ElxIconButton text='Copy' iconProps={{ iconName: 'Info' }} />
			</ElxCopy>
			<ElxTextField
				defaultValue={props.changedValue}
				id='scriptId'
				label='Script'
				multiline={true}
				rows={15}
				style={{ marginRight: 10 }}
				onChange={(event, newValue) => {
					setDisableRun(false);
					props.changedScriptValue(newValue ?? '');
				}}
			/>
			{/* <textarea
				defaultValue={props.defaultValue}
				id="scriptId"
				cols={100}
				rows={15}
			/>			 */}
			<br />
			<ElxSecondaryButton
				text='Run Script'
				disabled={disableRun}
				onClick={() => {
					onRunScript(props.scope, props.panelName);
				}}
				style={{ marginRight: 10 }}
			/>
			<ElxSecondaryButton
				text='Cancel Script'
				disabled={disableCancel}
				onClick={() => {
					onCancelScript(props.scope, props.panelName)
				}}
			/>
		</ElxScopedContainer>
	);
};

export default RunScripts;
